import Vue from 'vue';

import '@/initI18n';

import DaturaView from '@/datura/DaturaView.vue';
import PageNewDroneAssessment from '@/datura/PageNewDroneAssessment.vue';
import PageDroneAssessment from '@/datura/assessments/PageDroneAssessment.vue';
import PageHistory from '@/datura/history/PageHistory.vue';
import PageViewer from '@/datura/viewer/PageViewer.vue';
import { baseUrl } from '@/shared/constants';
import { availableFeatures } from '@/shared/storeDynamicFeatures';

const t = Vue.i18n.translate;

const back = {
  to: { name: 'drone' },
  label: () => `${t('Zurück zu')} ${t('Datura-Kontrollzentrum')}`,
};

export default [
  {
    path: `${baseUrl}datura`,
    name: 'datura',
    component: DaturaView,
    redirect: { name: 'drone' },
    meta: {
      label: () => t('Datura-Kontrollzentrum'),
      featureControl: availableFeatures.FEATURE_DATURA_CONTROL,
    },
    children: [
      {
        path: 'drone',
        name: 'drone',
        component: PageDroneAssessment,
        meta: {
          requiresAuth: true,
          label: () => t('Datura-Kontrollzentrum'),
          labelTab: () => t('Drohnenassessments'),
          featureControl: availableFeatures.FEATURE_DATURA_CONTROL,
        },
      },
      {
        path: 'drone/new',
        name: 'drone/new',
        component: PageNewDroneAssessment,
        meta: {
          requiresAuth: true,
          label: () => t('Assessment erstellen'),
          featureControl: availableFeatures.FEATURE_DATURA_CONTROL,
          subheader: () => t('Drohnenbilder hochladen, um ein neues Assessment zu starten'),
          back,
        },
      },
      {
        path: 'drone/viewer/:fileId',
        name: 'drone/viewer',
        component: PageViewer,
        meta: {
          requiresAuth: false,
          isFullscreen: true,
        },
      },
      {
        path: 'history',
        name: 'history',
        component: PageHistory,
        meta: {
          requiresAuth: true,
          label: () => t('Verlauf'),
          subheader: () => t('Den Status des aktuellen Auftrags und den Auftragsverlauf anzeigen'),
          featureControl: availableFeatures.FEATURE_DATURA_CONTROL,
          back,
        },
      },
    ],
  },
];
