import { Activity, ImportActivity } from 'farmdok-rest-api';
import Handsontable from 'handsontable';
import Vue from 'vue';

import { getNDecimals } from '@/activities/handsontable/columns/products';
import { Getters as PesticideIndicationsGetters } from '@/pesticideIndications/store/getters';
import isHerbicide from '@/products/herbizides/isHerbicide';
import { Getters as ProductGetters } from '@/products/store/getters';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { ColumnSettingsAmountUnitOptional } from '@/shared/handsontable/rework/cellTypes/amountUnit/types';
import { ColumnSettingsDropdownOptional } from '@/shared/handsontable/rework/cellTypes/dropdown/types';
import { ColumnSettingsOptional } from '@/shared/handsontable/rework/cellTypes/optionalRenderer/types';
import { columnSorting as columnSortingSubtablePrimaryColumn } from '@/shared/handsontable/rework/cellTypes/subtablePrimaryColumn';
import { ColumnSettingsSubtablePrimaryColumn } from '@/shared/handsontable/rework/cellTypes/subtablePrimaryColumn/types';
import { ColumnSettingsSubtable } from '@/shared/handsontable/rework/features/nestedTable/types';
import { DropdownItems } from '@/shared/handsontable/types';
import notNullOrUndefined from '@/shared/modules/notNullOrUndefinedFilter';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import store from '@/store';

import { DropdownMatch } from '../../types/TableDataImportActivity.types';

function getAdditionalProductDropdownItemsForImportActivity(
  productIds: string[],
  originalValue: string,
): DropdownItems[] {
  const additionalDropdownItems = productIds.reduce((acc: DropdownItem[], productId) => {
    const product = (store.getters['products/findProductById'] as ProductGetters['findProductById'])(productId);
    if (product) {
      acc.push({
        id: productId,
        name: product.name,
      });
    }

    return acc;
  }, []);

  const originalDropdownItems = {
    id: DropdownMatch.NO_MATCH,
    name: 'value from data source (from import)',
    items: originalValue ? [{ id: DropdownMatch.NO_MATCH, name: originalValue }] : [],
    sort: false,
  };

  const additionalDropdowns = {
    name: 'Matching products',
    id: 'products-match',
    items: additionalDropdownItems,
    sort: false,
  } as DropdownItems;

  if (originalValue) {
    return [originalDropdownItems, additionalDropdowns];
  }

  return [additionalDropdowns];
}

const renderEmpty = (visualRow: number, instance: Handsontable) => {
  const isExpanded = instance.getDataAtRowProp(visualRow, 'expand');
  return !isExpanded;
};

const colspanPrimaryColumn = (
  visualRow: number,
  value: DropdownItem | 'HOT_DISPLAY_BUTTON_RENDERER' | null,
  instance: Handsontable,
): number => {
  const visibleColumns = products.subtableColumns.filter((column) => !column.hidden).length;
  const pesticideIndicationColumnAvailable = pesticideIndicationColumn.requiredFeatures
    ? pesticideIndicationColumn.requiredFeatures()
    : true;

  const isExpanded = instance.getDataAtRowProp(visualRow, 'expand');
  if (!isExpanded) {
    return pesticideIndicationColumnAvailable ? visibleColumns : visibleColumns - 1;
  }

  if (value === null || value === 'HOT_DISPLAY_BUTTON_RENDERER' || !value.id)
    return pesticideIndicationColumnAvailable ? 2 : 1;

  const [productId] = value.id.split('_');
  const product = (store.getters['products/findProductById'] as ProductGetters['findProductById'])(productId);
  const productCategories = store.state.productCategories.data;

  if (isHerbicide(product, productCategories)) return 1;

  return pesticideIndicationColumnAvailable ? 2 : 1;
};

export const productStorageColumn: ColumnSettingsSubtablePrimaryColumn = {
  data: 'product.productStorageDropdownItem',
  type: 'farmdok.subtablePrimaryColumn',
  renderer: 'farmdok.optional',
  width: 200,
  colspan: (visualRow, visualColumn, value, instance) => colspanPrimaryColumn(visualRow, value, instance),
  renderLoading: (visualRow, value, instance: Handsontable) => {
    if (value && value.id !== '') return false;

    const isLoading = instance.getDataAtRowProp(visualRow, 'product.isLoading');
    return isLoading;
  },
  collapsedSubtable: {
    getRendererValue({ values }) {
      const displayNames = values.map((value) => value?.name ?? '');
      const joinedDisplayNames = displayNames.join(', ');

      return joinedDisplayNames;
    },
    onClick({ event, visualRow, instance: hot }) {
      event.preventDefault();
      hot.setDataAtRowProp(visualRow, 'expand', true);
    },
  },
  button: {
    label: () => `+ ${Vue.i18n.translate('Produkt hinzuf\u00fcgen')}` || '+ Produkt hinzuf\u00fcgen',
    color: 'primary',
    onClick({ visualRow, instance: hot }) {
      const activityId = hot.getDataAtRowProp(visualRow, 'id');
      store.dispatch('activities/importActivities/addImportActivityProduct', activityId);
    },
  },
  showWarning: (value) => value?.id === DropdownMatch.NO_MATCH,
  dropdown: {
    async getItems(visualRow: number, hot: Handsontable) {
      // find additional matches
      const importActivityId = hot.getDataAtRowProp(visualRow, 'id');
      const importActivity = store.getters['activities/importActivities/getById'](importActivityId) as ImportActivity;
      const matches = importActivity.products[0]?.productIdMatch?.additionalMatches ?? [];
      const originalValue = importActivity.products[0]?.productIdMatch?.originalValue ?? '';

      const additionalProducts = importActivity
        ? getAdditionalProductDropdownItemsForImportActivity(matches, originalValue)
        : [];

      const activityTypeId: string | undefined = hot.getDataAtRowProp(visualRow, 'activityTypeDropdownItem')?.id;
      if (!activityTypeId) return additionalProducts || [];
      const activityType = store.state.activityTypes.data[activityTypeId];

      const processOrderId: string | undefined = hot.getDataAtRowProp(visualRow, 'processOrderId');
      if (!processOrderId) return additionalProducts || [];
      const company = store.getters['auth/findCompanyByProcessOrderId'](processOrderId);
      if (!company) return additionalProducts || [];

      const currentProcessOrders = store.getters['auth/currentProcessOrders'];
      const productVisibilities = Object.values(store.state.productVisibilities.data);
      const productCategories = Object.values(store.state.productCategories.data);
      const activityTimeStarts = store.state.activities.createEditActivity.activities
        .map((activity: Activity) => activity?.timeStart)
        .filter(notNullOrUndefined);
      const fieldId: string | undefined = hot.getDataAtRowProp(visualRow, 'fieldDropdownItem')?.id;
      if (!fieldId) return additionalProducts || [];
      const field = store.state.fields.data[fieldId] ?? null;
      const storagePlaces = Object.values(store.state.storagePlaces.data);

      const compatibleProducts = (
        store.getters[
          'products/compatibleProductsToDropdownItems'
        ] as ProductGetters['compatibleProductsToDropdownItems']
      )(
        activityType,
        [company],
        currentProcessOrders,
        productVisibilities,
        productCategories,
        [field],
        storagePlaces,
        activityTimeStarts,
      );

      if (additionalProducts) {
        return [...additionalProducts, ...compatibleProducts];
      }

      return compatibleProducts;
    },
  },
  columnSorting: columnSortingSubtablePrimaryColumn,
  warningIcon: {
    tooltipLabel: Vue.i18n.translate(
      'Der Wert in der Datenquelle (aus dem Import) konnte nicht eindeutig erkannt werden. ' +
        'Bitte wähle eine Eintrag aus der Liste. Andernfalls kann kein Wert importiert werden.',
    ),
  },
};

const pesticideIndicationColumn: ColumnSettingsDropdownOptional = {
  data: 'product.pesticideIndicationDropdownItem',
  type: 'farmdok.dropdown',
  renderer: 'farmdok.optional',
  width: 200,
  requiredFeatures: () =>
    store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_PESTS) &&
    store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_PESTS),
  renderEmpty,
  renderNone: (visualRow, instance) => {
    const productStorageDropdownItem = instance.getDataAtRowProp(visualRow, 'product.productStorageDropdownItem');
    return colspanPrimaryColumn(visualRow, productStorageDropdownItem, instance) > 1;
  },
  dropdown: {
    async getItems(visualRow, hot) {
      const productStorageDropdownItem = hot.getDataAtRowProp(visualRow, 'product.productStorageDropdownItem');
      if (!productStorageDropdownItem) return [];
      const [productId] = productStorageDropdownItem.id.split('_');
      const fieldDropdownItem = hot.getDataAtRowProp(visualRow, 'fieldDropdownItem');
      const activityTimeStart = hot.getDataAtRowProp(visualRow, 'timeStart');
      const fieldId = fieldDropdownItem.id;

      if (!productId || !fieldId) return [];

      const items = (
        store.getters[
          'pesticideIndications/dropdownItemsForProductAndField'
        ] as PesticideIndicationsGetters['dropdownItemsForProductAndField']
      )(productId, fieldId, [activityTimeStart]);

      return items ?? [];
    },
  },
};

const amountUnitRelativeColumn: ColumnSettingsAmountUnitOptional = {
  data: 'product.amountUnitRelative',
  type: 'farmdok.amountUnit',
  renderer: 'farmdok.optional',
  noHeaderContextMenu: true,
  width: 120,
  renderEmpty,
  renderNone: (visualRow, instance) => {
    const productStorageDropdownItem = instance.getDataAtRowProp(visualRow, 'product.productStorageDropdownItem');
    return colspanPrimaryColumn(visualRow, productStorageDropdownItem, instance) > 2;
  },
  getUnitName(unitId) {
    const unit = store.state.units.data[unitId];

    return unit?.name ?? null;
  },
  getNDecimals,
};

const amountUnitTotalColumn: ColumnSettingsAmountUnitOptional = {
  data: 'product.amountUnitTotal',
  type: 'farmdok.amountUnit',
  renderer: 'farmdok.optional',
  noHeaderContextMenu: true,
  width: 120,
  renderEmpty,
  renderNone: (visualRow, instance) => {
    const productStorageDropdownItem = instance.getDataAtRowProp(visualRow, 'product.productStorageDropdownItem');
    return colspanPrimaryColumn(visualRow, productStorageDropdownItem, instance) > 3;
  },
  getUnitName(unitId) {
    const unit = store.state.units.data[unitId];

    return unit?.name ?? null;
  },
  getNDecimals,
};

const optionalTrashIconColumn: ColumnSettingsOptional = {
  data: 'productsTrashIcon', // this is just a dummy value, the column is not bound to any data (required for move columns via user column settings)
  type: 'farmdok.trashIcon',
  renderer: 'farmdok.optional',
  noHeaderContextMenu: true,
  width: 45,
  disableColumnResize: true,
  renderEmpty(visualRow, instance) {
    const activityProductId = instance.getDataAtRowProp(visualRow, 'product.id');
    return !activityProductId;
  },
  renderNone: (visualRow, instance) => {
    const productStorageDropdownItem = instance.getDataAtRowProp(visualRow, 'product.productStorageDropdownItem');
    return colspanPrimaryColumn(visualRow, productStorageDropdownItem, instance) > 3; // must also work if pesticideIndicationColumn is hidden
  },
  onClick(visualRow, hot) {
    if (!this.readOnly) {
      const importActivityId = hot.getDataAtRowProp(visualRow, 'id');
      const importActivityProductId = hot.getDataAtRowProp(visualRow, 'product.id');
      store.dispatch('activities/importActivities/removeImportActivityProduct', {
        importActivityId,
        importActivityProductId,
      });
    }
  },
};

const products: ColumnSettingsSubtable<
  | Handsontable.ColumnSettings
  | ColumnSettingsSubtablePrimaryColumn
  | ColumnSettingsDropdownOptional
  | ColumnSettingsOptional
> = {
  data: 'product',
  header: {
    title: () => Vue.i18n.translate('Produkte') ?? '',
  },
  type: 'subtable',
  subtableColumns: [
    productStorageColumn,
    pesticideIndicationColumn,
    amountUnitRelativeColumn,
    amountUnitTotalColumn,
    // is always hidden but needed in table for change detection of product.id,
    // not allowed to be first column (otherwise column would be always hidden in tableSettings),
    // not allowed to be the last column (to properly add subtable styles)
    { data: 'product.id', type: 'text', hidden: true },
    optionalTrashIconColumn,
  ],
};

export default products;
