import Vue from 'vue';

import '@/initI18n';

import PageHome from '@/PageHome.vue';
import activities from '@/activities/routes';
import auth from '@/auth/routes';
import datura from '@/datura/routes';
import digitalContracting from '@/digital-contracting/routes';
import fieldGroups from '@/field-groups/routes';
import fields from '@/fields/routes';
import PageNotFound from '@/layout/components/PageNotFound.vue';
import precisionFarming from '@/precision-farming/routes';
import router from '@/router';
import PageButtonTest from '@/shared/components/buttons/PageButtonTest.vue';
import PageFormTest from '@/shared/components/form/PageFormTest.vue';
import { REGION_GUIDS, baseUrl, origin, originStandalone } from '@/shared/constants';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import store from '@/store';

/**
 * Workaround to make navigating in AgriDat possible.
 * Redirects to the new page if the current page is served from the new webclient
 * or the user is navigating to a different page in AgriDat.
 *
 * @param to
 * @param from
 * @param next
 */
export const redirectToAgriDat = (to, from, next) => {
  if (
    process.env.VUE_APP_IS_IN_AGRIDAT !== '1' ||
    (from.name != null && to.name !== from.name) ||
    (['/comp/', '/ordr/', '/admin/'].some((urlPath) => from.path.includes(urlPath)) && from.path !== to.path)
  ) {
    store.commit('redirectToAgriDat');
    window.location = `${origin}${to.fullPath}`;
  } else {
    next();
  }
};

/**
 * Workaround to make navigating in AgriDat possible.
 * Redirects to the new page if the current page is served from AgriDat.
 *
 * @param to
 * @param from
 * @param next
 */
export const redirectToStandalone = (to, from, next) => {
  if (process.env.VUE_APP_IS_IN_AGRIDAT === '1') {
    window.location = `${originStandalone}${to.path}`;
  } else {
    next();
  }
};

export const routes = [
  {
    path: baseUrl,
    name: 'home',
    component: PageHome,
    meta: {
      isFullscreen: true,
      requiresAuth: true,
    },
  },
  // region - TESTING / DEVELOPMENT
  {
    path: `${baseUrl}button-test`,
    name: 'button-test',
    component: PageButtonTest,
    meta: {
      requiresAuth: true,
      label: 'Button Test',
    },
  },
  {
    path: `${baseUrl}form-test`,
    name: 'form-test',
    component: PageFormTest,
    meta: {
      requiresAuth: true,
      label: 'Form Test',
    },
  },
  // endregion
  // region - MODULES / FEATURES
  ...auth,
  ...digitalContracting,
  ...activities,
  ...datura,
  {
    path: `${baseUrl}more`,
    name: 'more',
    component: PageHome,
    meta: {
      requiresAuth: true,
    },
  },
  ...fieldGroups,
  ...fields,
  ...precisionFarming,
  // endregion
].map((route) => ({
  ...route,
  beforeEnter: (to, from, next) => {
    redirectToStandalone(to, from, () => {
      if (typeof route.beforeEnter === 'function') {
        route.beforeEnter(to, from, next);
      } else {
        next();
      }
    });
  },
}));

const backToManagement = {
  to: { name: 'management' },
  label: () => Vue.i18n.translate('Zurück zur Verwaltungs-Übersicht'),
};

export const routesAgriDat = [
  // region - MAIN ROUTES
  {
    path: '/admin/map',
    name: 'map',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Karte'),
    },
  },
  {
    path: '/admin/notes',
    name: 'notes',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Notizen'),
    },
  },
  {
    path: '/admin/reports',
    name: 'reports',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Berichte'),
    },
  },
  {
    path: '/shop',
    name: 'shop',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Shop'),
    },
  },
  {
    path: '/voucher/referral',
    name: 'referral',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Freunde einladen'),
    },
  },
  // endregion
  // region - MODULES / FEATURES
  {
    path: '/admin/fields',
    name: 'fields--agridat',
    meta: {
      requiresAuth: true,
      label({ hash } = {}) {
        switch (hash) {
          case '#soil-samples':
            return Vue.i18n.translate('Bodenproben');
          case '#crop-rotation':
            return Vue.i18n.translate('Fruchtfolge');
          default:
            return Vue.i18n.translate('Felder');
        }
      },
    },
  },
  {
    path: '/admin/fields#soil-samples',
    name: 'soil-samples--agridat',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Bodenproben'),
      featureControl: availableFeatures.FEATURE_SOIL_SAMPLES,
    },
  },
  {
    path: '/admin/fields#crop-rotation',
    name: 'crop-rotation--agridat',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Fruchtfolge'),
      featureControl: availableFeatures.FEATURE_CROP_ROTATION,
    },
  },
  // endregion
  // region - ADDITIONAL ROUTES
  {
    path: '/admin/user/profile',
    name: 'profile',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Profil'),
    },
  },
  {
    path: '/admin/user/invoices',
    name: 'my-invoices',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Meine Rechnungen'),
    },
  },
  {
    path: '/aboutus',
    name: 'about-us',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Impressum'),
    },
  },
  {
    path: '/admin/messaging',
    name: 'notifications',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Benachrichtigungen'),
    },
  },
  {
    path: '/admin/croppingPlan',
    name: 'fertilization-plan',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Düngeplanung'),
      featureControl: availableFeatures.FEATURE_FERTILIZATION_PLAN,
    },
  },
  {
    path: '/admin/at/fertilizerPlan',
    name: 'fertilization-plan--at',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Düngeplanung'),
      featureControl: availableFeatures.FEATURE_FERTILIZATION_PLAN,
    },
  },
  {
    path: '/admin/de/fertilizerPlan',
    name: 'fertilization-plan--de',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Düngeplanung'),
      featureControl: availableFeatures.FEATURE_FERTILIZATION_PLAN,
    },
  },
  {
    path: '/admin/regional/de/fertilizerRegulation',
    name: 'fertilizer-regulation',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Düngeverordnung'),
      featureControl: availableFeatures.FEATURE_FERTILIZER_REGULATION,
    },
  },
  {
    path: '/admin/regional/de/nutrientComparison',
    name: 'nutrient-comparison',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Nährstoffvergleich'),
      featureControl: availableFeatures.FEATURE_NUTRIENT_COMPARISON,
    },
  },
  {
    path: '/admin/regional/de/substanceFlow',
    name: 'substance-flow',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Stoffstrombilanz'),
      featureControl: availableFeatures.FEATURE_SUBSTANCE_FLOW,
    },
  },
  {
    path: '/admin/taskplan/list',
    name: 'task-plan',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Arbeitsplanung'),
      featureControl: availableFeatures.FEATURE_TASK_PLAN,
    },
  },
  {
    path: '/admin/stockmovement',
    name: 'stock-movement',
    meta: {
      requiresAuth: true,
      label({ hash } = {}) {
        switch (hash) {
          case '#stock-overview':
            return Vue.i18n.translate('Lagerstände');
          default:
            return Vue.i18n.translate('Lagerbewegungen');
        }
      },
      featureControl: availableFeatures.FEATURE_STOCK_MOVEMENT,
    },
  },
  {
    path: '/admin/prices/main',
    name: 'cost-accounting',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Kostenrechnung'),
      featureControl: availableFeatures.FEATURE_COST_ACCOUNTING,
    },
  },
  {
    path: '/admin/menu/management',
    name: 'management',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Verwaltung'),
    },
  },
  {
    path: '/admin/user/list',
    name: 'management/people',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Personen'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/company',
    name: 'management/company',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Betrieb'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/customer/customerManagement',
    name: 'management/customer-management',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Kundenverwaltung'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/imexports',
    name: 'management/exports',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Exporte'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/company/settings',
    name: 'management/company-settings',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Einstellungen'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/material/category',
    name: 'management/category',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Betriebsmittelkategorien'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/material/crop',
    name: 'management/material/crop',
    meta: {
      requiresAuth: true,
      label({ hash } = {}) {
        switch (hash) {
          case '#harvest':
            return Vue.i18n.translate('Erntegüter');
          case '#varieties':
            return Vue.i18n.translate('Sorten');
          case '#seeds':
            return Vue.i18n.translate('Saatgut');
          default:
            return Vue.i18n.translate('Kulturen');
        }
      },
      back: backToManagement,
    },
  },
  {
    path: '/admin/material/mineralFertilizer',
    name: 'management/material/mineral-fertilizer',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Mineraldünger'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/material/farmManure',
    name: 'management/material/farm-fertilizer',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Wirtschaftsdünger'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/material/secondaryFertilizer',
    name: 'management/material/secondary-fertilizer',
    meta: {
      requiresAuth: true,
      label() {
        if (store.getters['auth/currentCompaniesRegionIds'].includes(REGION_GUIDS.DE)) {
          return Vue.i18n.translate('Sonstige organische Düngemittel');
        }
        return Vue.i18n.translate('Sekundärrohstoffe');
      },
      back: backToManagement,
    },
  },
  {
    path: '/admin/material/herbicide',
    name: 'management/material/herbicide',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Pflanzenschutzmittel'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/material/otherAuxiliaryFertilizer',
    name: 'management/material/other-auxiliary-fertilizer',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Sonstige Hilfsstoffe und Dünger'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/storage',
    name: 'management/storage',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Lager'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/animals/animalCategoryValues',
    name: 'management/animals',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Tierkategorien und Nährstoffanfall'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/equipment/main',
    name: 'management/equipment',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Maschinen & Geräte'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/equipment/category/main',
    name: 'management/equipment-category',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Maschinen & Geräte Kategorien'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/equipment/lists/main',
    name: 'management/equipment-lists',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Maschinen & Geräte Listen'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/measures/evaluation',
    name: 'management/measures-evaluation',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Auswertung'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/activities',
    name: 'management/activity-types',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Tätigkeiten'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/activities/fine',
    name: 'management/activity-types-fine',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Detaillierte Tätigkeiten'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/activities/rough',
    name: 'management/activity-types-rough',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Grobe Tätigkeiten'),
      back: backToManagement,
    },
  },
  {
    path: '/admin/units',
    name: 'management/units',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Einheiten'),
      back: backToManagement,
    },
  },

  // endregion
  // region - ADMIN (SU) ROUTES
  {
    path: '/admin/user/admin',
    name: 'admin/users',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'All users',
    },
  },
  {
    path: '/admin/user/instance',
    name: 'admin/app-instances',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'User app instances',
    },
  },
  {
    path: '/admin/user/list/partner',
    name: 'admin/partners',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Partners',
    },
  },
  {
    path: '/admin/company/management',
    name: 'admin/companies',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Companies',
    },
  },
  {
    path: '/admin/company/suList/1',
    name: 'admin/switch-company',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Switch company',
    },
  },
  {
    path: '/admin/company/management/multicompany',
    name: 'admin/multi-companies',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Multicompany',
    },
  },
  {
    path: '/voucher/administration',
    name: 'admin/used-vouchers',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Used vouchers',
    },
  },
  {
    path: '/admin/sbt/administration',
    name: 'admin/agb',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'AGB versions',
    },
  },
  {
    path: '/admin/license/order/table',
    name: 'admin/orders',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Orders',
    },
  },
  {
    path: '/admin/license/licenses',
    name: 'admin/licenses',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Licenses',
    },
  },
  {
    path: '/admin/license/invoices',
    name: 'admin/invoices',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Invoices',
    },
  },
  {
    path: '/admin/reports/administration',
    name: 'admin/reports',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Reports',
    },
  },
  {
    path: '/admin/accesscontrol/permissiongroups',
    name: 'admin/permission-groups',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Permission groups',
    },
  },
  {
    path: '/admin/accesscontrol/userroles',
    name: 'admin/user-roles',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'User roles',
    },
  },
  {
    path: '/admin/accesscontrol/licensemodule',
    name: 'admin/license-modules',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'License modules',
    },
  },
  {
    path: '/admin/accesscontrol/licensepackages',
    name: 'admin/license-packages',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'License packages',
    },
  },
  {
    path: '/admin/accesscontrol/jsonexport',
    name: 'admin/export',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Export',
    },
  },
  {
    path: '/admin/accesscontrol/jsonimport',
    name: 'admin/import',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Import',
    },
  },
  {
    path: '/admin/i18n/language/manage',
    name: 'admin/languages',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Languages',
    },
  },
  {
    path: '/admin/material/materialGrabber',
    name: 'admin/material-import',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Material import',
    },
  },
  {
    path: '/admin/fields/import/mappings',
    name: 'admin/field-import-mappings',
    meta: {
      requiresAuth: true,
      requiresSuperAdmin: true,
      label: 'Field import mappings',
    },
  },
  // endregion
  // region - PARTNER ROUTES
  {
    path: '/voucher/list/bundles',
    name: 'partner/voucher-bundles',
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Gutscheine verwalten'),
    },
  },
  // endregion
].reduce((accumulator, route) => {
  const currentRoutes = [];
  currentRoutes.push(
    {
      ...route,
      beforeEnter: redirectToAgriDat,
    },
    ...['/comp/:companyId', '/ordr/:orderId', '/comp/:companyId/ordr/:orderId'].map((slug) => ({
      path: slug + route.path,
      redirect: { name: route.name },
    })),
  );
  return [...accumulator, ...currentRoutes];
}, []);

export default function init() {
  routes.forEach((route) => router.addRoute(route));
  routesAgriDat.forEach((route) => router.addRoute(route));
  router.addRoute({
    path: '*',
    name: '404',
    component: PageNotFound,
    meta: {
      requiresAuth: true,
    },
  });
  router.addRoute({
    path: `${baseUrl}logout`,
    name: 'logout',
    beforeEnter: async (to, from, next) => {
      await store.dispatch('auth/logout');
      next({ name: 'login' });
    },
  });

  /**
   * init auth route guards
   */
  router.beforeEach(async (to, from, next) => {
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
      return next();
    }
    await store.dispatch('auth/subscribe');
    const { oneTimeToken } = to.query;
    if (!store.state.auth.loggedIn) {
      const nextRoute = { name: 'login' };
      if (to.fullPath !== '/' && to.fullPath !== baseUrl) {
        nextRoute.query = { redirect: to.fullPath, oneTimeToken };
      }
      return next(nextRoute);
    }
    if (to.meta.requiresSuperAdmin && !store.state.auth.user.superAdmin) {
      return next(false);
    }
    return next();
  });

  router.afterEach((to) => {
    // Use next tick to handle router history correctly
    // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    Vue.nextTick(() => {
      let title = null;
      if (typeof to.meta.label === 'string') {
        title = to.meta.label;
      } else if (typeof to.meta.label === 'function') {
        title = to.meta.label(to);
      }
      document.title = title != null ? `${title} – FARMDOK` : 'FARMDOK';
    });
  });
}

/**
 * Returns an object containing the feature settings (determined from storeDynamicFeatures) for a given route
 *
 * @param route
 * @returns {{visible: boolean, readonly: boolean, enabled: boolean}}
 */
export function getFeatureSettingsForRoute(route) {
  if (route.meta == null || route.meta.featureControl == null) {
    return {
      visible: true,
      enabled: true,
      readonly: false,
    };
  }
  return {
    visible: store.getters.currentCompaniesHaveFeatureVisible(route.meta.featureControl),
    enabled: store.getters.currentCompaniesHaveFeatureEnabled(route.meta.featureControl),
    readonly: store.getters.currentCompaniesHaveFeatureReadonly(route.meta.featureControl),
  };
}
