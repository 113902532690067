import Vue from 'vue';

import { fieldGroupNumber, fieldNumber, fullFieldName, name, size } from '@/fields/handsontable/columns/columns';
import { selectColumn } from '@/shared/handsontable/mixins/featureSelectableRows';
import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';
import rootStore from '@/store';

import { STORE_KEY_AMA_CODES, STORE_KEY_KIND_OF_USE_TYPES } from '../../mixins/containers/dropdownItemsForFields';

const $t = Vue.i18n.translate;

// region - columns
export const columnImportedFieldGroupNr = {
  ...fieldGroupNumber,
  key: 'importedFieldGroup.number',
  lockedVisibility: true,
  lockedPosition: true,
  readOnly: true,
  className: 'imported-data',
  sortOrder: 'asc',
};

export const columnImportedFieldNumber = {
  ...fieldNumber,
  key: 'importedField.number',
  lockedVisibility: true,
  lockedPosition: true,
  readOnly: true,
  className: 'imported-data',
};

export const columnImportedFieldName = {
  ...name,
  key: 'importedField.name',
  width: 220,
  lockedVisibility: true,
  lockedPosition: true,
  readOnly: true,
  className: 'imported-data border-right text-bold',
};

export const columnMatchedCustomerId = {
  key: 'mappedCustomer.id',
  header: {
    title: () => Vue.i18n.translate('Kunde'),
  },
  type: 'dropdown',
  width: 240,
  className: 'border-right imported-data',
  getRendererValue({ value }) {
    if (!value) {
      return '';
    }

    return rootStore.state.customers.data[value].name;
  },
  async getItems() {
    if (this.$parent.fields == null) {
      return [];
    }
    const customers = Object.values(rootStore.state.customers.data);
    return [
      {
        name: null,
        id: 'reset',
        items: [{ id: '', name: '-' }],
      },
      {
        name: this.$t('Kunden'),
        id: 'customers',
        items: customers.map((customer) => ({
          id: customer.id,
          name: customer.name,
          disabled: false,
        })),
      },
    ];
  },
  hoverNotification({ visualRow, target }) {
    const id = this.visualRowToGuid(visualRow);
    this.$emit('hoverNotification', {
      id,
      target,
    });
  },
};

export const columnMatchedFieldId = {
  key: 'mappedField.id',
  header: {
    title: () => Vue.i18n.translate('Feld'),
  },
  type: 'dropdown',
  width: 300,
  className: 'border-right',
  getRendererValue({ value, visualRow }) {
    const guid = this.visualRowToGuid(visualRow);
    if (!value && this.tableData[guid] == null) {
      return '';
    }
    const fieldId = value || this.tableData[guid][columnMatchedFieldId.key];
    if (this.$parent.fields == null || this.$parent.fields[fieldId] == null) {
      return this.$t('Neu');
    }
    return fullFieldName.data(this.$parent.fields[fieldId]);
  },
  async getItems(visualRow, hot) {
    if (this.$parent.fields == null) {
      return [];
    }
    const usedFields = Object.values(this.tableData).reduce((usedFieldsCurrent, importedField) => {
      if (importedField[columnMatchedFieldId.key] == null) {
        return usedFieldsCurrent;
      }
      return [...usedFieldsCurrent, importedField[columnMatchedFieldId.key]];
    }, []);
    const physicalCol = hot.getSettings().columns.findIndex((col) => col.key === columnMatchedCustomerId.key);
    const visualColumn = hot.toVisualColumn(physicalCol);
    const customerId = hot.getDataAtCell(visualRow, visualColumn);
    return [
      {
        name: null,
        id: 'reset',
        items: [{ id: 'new_field', name: this.$t('Neu') }],
      },
      {
        name: this.$t('Felder'),
        id: 'fields',
        items: Object.values(this.$parent.fields)
          .filter((field) => (customerId ? field.fieldGroup.customerId === customerId : true))
          .map((field) => ({
            id: field.id,
            name: fullFieldName.data(field),
            disabled: usedFields.includes(field.id),
          })),
      },
    ];
  },
};

export const columnCropNameImported = {
  key: 'importedCrop.id',
  header: {
    title: () => `${$t('Kultur')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  type: 'dropdown',
  width: 250,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedCrop.name')
    );
  },
  getRendererValue({ value, visualRow }) {
    if (value == null) {
      const id = this.visualRowToGuid(visualRow);
      return this.tableData[id]['importedCrop.name'];
    }
    return this.$parent.columnDropdownCropsGetRendererValue(null, { value });
  },
  async getItems(visualRow) {
    const itemGroups = await this.$parent.columnDropdownCropsGetItems();
    const id = this.visualRowToGuid(visualRow);
    if (!Array.isArray(this.tableData[id]['mappedCrop.id']) || this.tableData[id]['mappedCrop.id'].length === 0) {
      return itemGroups;
    }
    return [
      {
        id: 'crops-suggestions',
        items: this.tableData[id]['mappedCrop.id']
          .filter((cropId) => this.$parent.crops[cropId] != null)
          .map((cropId) => ({ id: cropId, name: this.$parent.crops[cropId].name })),
        name: Vue.i18n.translate('Passende Kulturen'),
        sort: true,
      },
      ...itemGroups,
    ];
  },
  hoverNotification({ visualRow, target }) {
    const id = this.visualRowToGuid(visualRow);
    this.$emit('hoverNotification', {
      id,
      target,
    });
  },
};

export const columnCropPreview = {
  key: 'cropId',
  header: {
    title: () => `${$t('Kultur')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'text',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedCrop.name')
    );
  },
  data({ id }) {
    return this.$parent.getCropNameForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.cropImportOption;
  },
};

export const columnSizeImported = {
  ...size,
  key: 'importedField.fieldSize',
  header: {
    title: () => `${$t('Größe')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  width: 180,
  readOnly: true,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.fieldSize')
    );
  },
};

export const columnSizePreview = {
  key: 'fieldSize',
  header: {
    title: () => `${$t('Größe')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'numeric',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.fieldSize')
    );
  },
  data({ id }) {
    return this.$parent.getFieldSizeForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.fieldSizeImportOption;
  },
};

export const columnKindOfUseImported = {
  key: 'importedField.kindOfUseId',
  header: {
    title: () => `${$t('Nutzungsart')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  type: 'dropdown',
  width: 250,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.kindofuse')
    );
  },
  getRendererValue({ value, visualRow }) {
    if (value == null) {
      const id = this.visualRowToGuid(visualRow);
      return this.tableData[id]['importedField.kindofuse'];
    }
    return this.$parent.dropdownItemsForFieldsGetRendererValue(STORE_KEY_KIND_OF_USE_TYPES, { value });
  },
  async getItems() {
    return this.$parent.dropdownItemsForFieldsGetItems(STORE_KEY_KIND_OF_USE_TYPES);
  },
  hoverNotification({ visualRow, target }) {
    const id = this.visualRowToGuid(visualRow);
    this.$emit('hoverNotification', {
      id,
      target,
    });
  },
};

export const columnKindOfUsePreview = {
  key: 'kindofuse',
  header: {
    title: () => `${$t('Nutzungsart')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'text',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.kindofuse')
    );
  },
  data({ id }) {
    return this.$parent.getKindOfUseForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.kindOfUseImportOption;
  },
};

export const columnVarietyImported = {
  key: 'importedField.varietyId',
  header: {
    title: () => `${$t('Sorte')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  type: 'dropdown',
  width: 250,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedVariety.name')
    );
  },
  getRendererValue({ value, visualRow }) {
    if (value == null) {
      const id = this.visualRowToGuid(visualRow);
      return this.tableData[id]['importedVariety.name'];
    }
    return this.$parent.columnDropdownVarietiesGetRendererValue(null, { value });
  },
  async getItems(visualRow) {
    const itemGroups = await this.$parent.columnDropdownVarietiesGetItems();
    const id = this.visualRowToGuid(visualRow);
    if (!Array.isArray(this.tableData[id]['mappedVariety.id']) || this.tableData[id]['mappedVariety.id'].length === 0) {
      return itemGroups;
    }
    return [
      {
        id: 'variety-suggestions',
        items: this.tableData[id]['mappedVariety.id']
          .filter((varietyId) => this.$parent.varieties[varietyId] != null)
          .map((varietyId) => ({ id: varietyId, name: this.$parent.varieties[varietyId].name })),
        name: Vue.i18n.translate('Passende Sorten'),
        sort: true,
      },
      ...itemGroups,
    ];
  },
  hoverNotification({ visualRow, target }) {
    const id = this.visualRowToGuid(visualRow);
    this.$emit('hoverNotification', {
      id,
      target,
    });
  },
};

export const columnVarietyPreview = {
  key: 'varietyId',
  header: {
    title: () => `${$t('Sorte')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'text',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedVariety.name')
    );
  },
  data({ id }) {
    return this.$parent.getVarietyNameForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.varietyImportOption;
  },
};

export const columnPreCropImported = {
  key: 'importedField.preCropId',
  header: {
    title: () => `${$t('Vorfrucht')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  type: 'dropdown',
  width: 250,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedPreCrop.name')
    );
  },
  getRendererValue({ value, visualRow }) {
    if (value == null) {
      const id = this.visualRowToGuid(visualRow);
      return this.tableData[id]['importedPreCrop.name'];
    }
    return this.$parent.columnDropdownCropsGetRendererValue(null, { value });
  },
  async getItems(visualRow) {
    const itemGroups = await this.$parent.columnDropdownCropsGetItems();
    const id = this.visualRowToGuid(visualRow);
    if (!Array.isArray(this.tableData[id]['mappedPreCrop.id']) || this.tableData[id]['mappedPreCrop.id'].length === 0) {
      return itemGroups;
    }
    return [
      {
        id: 'crops-suggestions',
        items: this.tableData[id]['mappedPreCrop.id']
          .filter((cropId) => this.$parent.crops[cropId] != null)
          .map((cropId) => ({ id: cropId, name: this.$parent.crops[cropId].name })),
        name: Vue.i18n.translate('Passende Kulturen'),
        sort: true,
      },
      ...itemGroups,
    ];
  },
  hoverNotification({ visualRow, target }) {
    const id = this.visualRowToGuid(visualRow);
    this.$emit('hoverNotification', {
      id,
      target,
    });
  },
};

export const columnPreCropPreview = {
  key: 'preCropId',
  header: {
    title: () => `${$t('Vorfrucht')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'text',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedPreCrop.name')
    );
  },
  data({ id }) {
    return this.$parent.getPreCropNameForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.preCropImportOption;
  },
};

export const columnCommentImported = {
  key: 'importedField.comment',
  header: {
    title: () => `${$t('Kommentar')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  type: 'text',
  width: 250,
  readOnly: true,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.comment')
    );
  },
};

export const columnCommentPreview = {
  key: 'comment',
  header: {
    title: () => `${$t('Kommentar')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'text',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.comment')
    );
  },
  data({ id }) {
    return this.$parent.getCommentForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.commentImportOption;
  },
};

export const columnAmaCodeImported = {
  key: 'importedField.amaCodeId',
  header: {
    title: () => `${$t('AMA Code')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  type: 'dropdown',
  width: 250,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.amaCode')
    );
  },
  getRendererValue({ value, visualRow }) {
    if (value == null) {
      const id = this.visualRowToGuid(visualRow);
      return this.tableData[id]['importedField.amaCode'];
    }
    return this.$parent.dropdownItemsForFieldsGetRendererValue(STORE_KEY_AMA_CODES, { value });
  },
  async getItems() {
    return this.$parent.dropdownItemsForFieldsGetItems(STORE_KEY_AMA_CODES);
  },
  hoverNotification({ visualRow, target }) {
    const id = this.visualRowToGuid(visualRow);
    this.$emit('hoverNotification', {
      id,
      target,
    });
  },
};

export const columnAmaCodePreview = {
  key: 'amaCode',
  header: {
    title: () => `${$t('AMA Code')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'text',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.amaCode')
    );
  },
  data({ id }) {
    return this.$parent.getAmaCodeForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.amaCodeImportOption;
  },
};

export const columnCadastralCommunityImported = {
  key: 'importedField.additionalData.cadastralCommunity',
  header: {
    title: () => `${$t('Gemeinde')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  type: 'text',
  width: 250,
  readOnly: true,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.additionalData.cadastralCommunity')
    );
  },
};

export const columnCadastralCommunityPreview = {
  key: 'additionalData.cadastralCommunity',
  header: {
    title: () => `${$t('Gemeinde')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'text',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.additionalData.cadastralCommunity')
    );
  },
  data({ id }) {
    return this.$parent.getCadastralCommunityForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.cadastralCommunityImportOption;
  },
};

export const columnPlotsImported = {
  key: 'importedField.additionalData.plots',
  header: {
    title: () => `${$t('Parzellen Nr.')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  type: 'text',
  width: 250,
  readOnly: true,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.additionalData.plots')
    );
  },
};

export const columnPlotsPreview = {
  key: 'additionalData.plots',
  header: {
    title: () => `${$t('Parzellen Nr.')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'text',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.additionalData.plots')
    );
  },
  data({ id }) {
    return this.$parent.getPlotsForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.plotsImportOption;
  },
};

export const columnOwnerImported = {
  key: 'importedField.additionalData.owner',
  header: {
    title: () => `${$t('Eigentümer')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  type: 'text',
  width: 250,
  readOnly: true,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.additionalData.owner')
    );
  },
};

export const columnOwnerPreview = {
  key: 'additionalData.owner',
  header: {
    title: () => `${$t('Eigentümer')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'text',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.additionalData.owner')
    );
  },
  data({ id }) {
    return this.$parent.getOwnerForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.ownerImportOption;
  },
};

export const columnRightOfUseImported = {
  key: 'importedField.additionalData.rightOfUse',
  header: {
    title: () => `${$t('Nutzung durch')} (${$t('aus Import')})`,
    description: () => Vue.i18n.translate('Aus importierter Datei.'),
  },
  type: 'text',
  width: 250,
  readOnly: true,
  className: 'imported-data',
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.additionalData.rightOfUse')
    );
  },
};

export const columnRightOfUsePreview = {
  key: 'additionalData.rightOfUse',
  header: {
    title: () => `${$t('Nutzung durch')} (${$t('in FARMDOK')})`,
    description: () => Vue.i18n.translate('In FARMDOK nach dem Import.'),
    links: [
      { text: 'Überschreiben', value: 'overwrite' },
      { text: 'Behalten', value: 'keep' },
      { text: 'Leere ergänzen', value: 'fill' },
    ],
  },
  type: 'text',
  width: 300,
  className: 'border-right',
  readOnly: true,
  requiredFeatures(store, context) {
    return (
      context != null &&
      context.importData != null &&
      Array.isArray(context.importData.mappedColumns) &&
      context.importData.mappedColumns.includes('importedField.additionalData.rightOfUse')
    );
  },
  data({ id }) {
    return this.$parent.getRightOfUseForImportedRow(id);
  },
  getCurrentHeaderLinkValue() {
    return this.$parent.rightOfUseImportOption;
  },
};
// endregion - columns

export const tableFieldsImport = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  columnMatchedCustomerId,
  columnImportedFieldGroupNr,
  columnImportedFieldNumber,
  columnImportedFieldName,
  columnMatchedFieldId,
  columnCropNameImported,
  columnCropPreview,
  columnSizeImported,
  columnSizePreview,
  columnKindOfUseImported,
  columnKindOfUsePreview,
  columnVarietyImported,
  columnVarietyPreview,
  columnPreCropImported,
  columnPreCropPreview,
  columnCommentImported,
  columnCommentPreview,
  columnAmaCodeImported,
  columnAmaCodePreview,
  columnCadastralCommunityImported,
  columnCadastralCommunityPreview,
  columnPlotsImported,
  columnPlotsPreview,
  columnOwnerImported,
  columnOwnerPreview,
  columnRightOfUseImported,
  columnRightOfUsePreview,
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];

export const tableFieldsImportEmptyProcessOrder = [
  {
    ...selectColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
  columnMatchedCustomerId,
  columnImportedFieldGroupNr,
  columnImportedFieldNumber,
  columnImportedFieldName,
  columnCropNameImported,
  columnSizeImported,
  columnKindOfUseImported,
  columnVarietyImported,
  columnPreCropImported,
  columnCommentImported,
  columnAmaCodeImported,
  columnCadastralCommunityImported,
  columnPlotsImported,
  columnOwnerImported,
  columnRightOfUseImported,
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];
