import { RuleViolationType } from 'farmdok-rest-api';
import Handsontable from 'handsontable';
import Vue from 'vue';

import { Getters as ActivityTypeGetters } from '@/activityTypes/store/getters';
import { Getters as FieldGetters } from '@/fields/store/getters';
import { ColumnSettingsCheckboxOptional } from '@/shared/handsontable/renderers/types';
import { columnSorting as columnSortingDate } from '@/shared/handsontable/rework/cellTypes/date';
import { columnSorting as columnSortingDropdown } from '@/shared/handsontable/rework/cellTypes/dropdown';
import { ColumnSettingsDropdownOptional } from '@/shared/handsontable/rework/cellTypes/dropdown/types';
import { expandColumn } from '@/shared/handsontable/rework/cellTypes/expandRenderer';
import { ColumnSettingsOptional } from '@/shared/handsontable/rework/cellTypes/optionalRenderer/types';
import { ColumnSettingsRuleCheckStatusOptional } from '@/shared/handsontable/rework/cellTypes/ruleCheckStatusRenderer/types';
import isFirstRowOfEntry from '@/shared/handsontable/rework/features/nestedTable/utils/isFirstRowOfEntry';
import placeholderColumn from '@/shared/handsontable/rework/features/placeholderColumn/column';
import storeStatusColumn from '@/shared/handsontable/rework/features/rowBackgroundStoreStatus/column';
import { FarmdokColumnSettings } from '@/shared/handsontable/rework/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import store from '@/store';

import equipment from './equipment';
import products from './products';

const renderEmpty = (visualRow: number, instance: Handsontable) => !isFirstRowOfEntry(visualRow, instance);

export const id: Handsontable.ColumnSettings = {
  data: 'id',
  header: {
    title: () => 'ID',
  },
  hiddenInPageSettings: true,
  hidden: true,
  width: 200,
};

export const selectColumn: ColumnSettingsCheckboxOptional = {
  data: 'select',
  type: 'farmdok.checkbox.optional',
  renderEmpty,
  noHeaderContextMenu: true,
  hiddenInPageSettings: true,
  width: 45,
  disableColumnResize: true,
};

export const ruleCheckStatus: ColumnSettingsRuleCheckStatusOptional = {
  data: 'ruleCheckStatus',
  type: 'farmdok.ruleCheckStatus',
  renderer: 'farmdok.optional',
  noHeaderContextMenu: true,
  hiddenInPageSettings: true,
  renderEmpty,
  requiredFeatures: () =>
    store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_RULE_CHECK) &&
    store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_RULE_CHECK),
  onClick(visualRow, hot) {
    const value = hot.getDataAtRowProp(visualRow, 'ruleCheckStatus');
    if (!value?.type) return;
    if (![RuleViolationType.NotChecked, RuleViolationType.Violation].includes(value.type)) return;

    const activityId = hot.getDataAtRowProp(visualRow, 'id');
    store.commit('activities/ruleCheck/showRuleViolationsModal', activityId);
  },
  width: 45,
  disableColumnResize: true,
};

export const company: ColumnSettingsOptional = {
  data: 'companyName',
  type: 'text',
  renderer: 'farmdok.optional',
  header: {
    title: () => Vue.i18n.translate('Betrieb'),
  },
  readOnly: true,
  width: 200,
  renderEmpty,
  requiredFeatures: () => {
    const { currentCompanies } = store.state.auth;
    return currentCompanies.length > 1;
  },
};

export const date: ColumnSettingsOptional = {
  data: 'timeStart',
  header: {
    title: () => Vue.i18n.translate('Datum'),
  },
  type: 'date',
  renderer: 'farmdok.optional',
  width: 120,
  columnSorting: columnSortingDate,
  initialSortOrder: 'desc',
  renderEmpty,
};

export const activityTypeColumn: ColumnSettingsDropdownOptional = {
  data: 'activityTypeDropdownItem',
  header: {
    title: () => Vue.i18n.translate('Tätigkeit') ?? '',
  },
  fixedColumnsLeftUntilHere: true,
  className: 'border-right text-bold',
  type: 'farmdok.dropdown',
  renderer: 'farmdok.optional',
  required: true,
  width: 200,
  renderEmpty,
  dropdown: {
    async getItems() {
      const { currentCompanies } = store.state.auth;
      const dropdownItems = (store.getters['activityTypes/dropdownItems'] as ActivityTypeGetters['dropdownItems'])(
        currentCompanies,
      );

      return dropdownItems;
    },
  },
  columnSorting: columnSortingDropdown,
};

export const fieldColumn: ColumnSettingsDropdownOptional = {
  data: 'fieldDropdownItem',
  header: {
    title: () => Vue.i18n.translate('FSNr-Schlag') ?? '',
    description: () => Vue.i18n.translate('Feldstücknummer - Feldname'),
  },
  type: 'farmdok.dropdown',
  renderer: 'farmdok.optional',
  width: 250,
  renderEmpty,
  dropdown: {
    async getItems(visualRow, hot) {
      const processOrderId: string | undefined = hot.getDataAtRowProp(visualRow, 'processOrderId');
      if (!processOrderId) return [];

      return (store.getters['fields/dropdownItems'] as FieldGetters['dropdownItems'])(processOrderId);
    },
  },
  columnSorting: columnSortingDropdown,
};

export const processedArea: ColumnSettingsOptional = {
  data: 'processedArea',
  header: {
    title: () => Vue.i18n.translate('Hektar'),
    description: () => Vue.i18n.translate('bearbeitete Fläche (ha)'),
  },
  type: 'numeric',
  renderer: 'farmdok.optional',
  width: 100,
  renderEmpty,
};

export const workingTime: ColumnSettingsOptional = {
  data: 'workingTime',
  header: {
    title: () => Vue.i18n.translate('Arbeitszeit'),
  },
  type: 'farmdok.duration',
  renderer: 'farmdok.optional',
  width: 100,
  renderEmpty,
};

export const drivingTime: ColumnSettingsOptional = {
  data: 'drivingTime',
  header: {
    title: () => Vue.i18n.translate('Fahrzeit'),
  },
  type: 'farmdok.duration',
  renderer: 'farmdok.optional',
  width: 100,
  renderEmpty,
  hidden: true,
};

export const setupTime: ColumnSettingsOptional = {
  data: 'setupTime',
  header: {
    title: () => Vue.i18n.translate('Rüstzeit'),
  },
  type: 'farmdok.duration',
  renderer: 'farmdok.optional',
  width: 100,
  renderEmpty,
  hidden: true,
};

export const pauseTime: ColumnSettingsOptional = {
  data: 'pauseTime',
  header: {
    title: () => Vue.i18n.translate('Pause'),
  },
  type: 'farmdok.duration',
  renderer: 'farmdok.optional',
  width: 100,
  renderEmpty,
  hidden: true,
};

export const userComment: ColumnSettingsOptional = {
  data: 'userComment',
  header: {
    title: () => Vue.i18n.translate('Bemerkung'),
  },
  type: 'text',
  renderer: 'farmdok.optional',
  width: 200,
  renderEmpty,
};

export const user: ColumnSettingsOptional = {
  data: 'user',
  header: {
    title: () => Vue.i18n.translate('Benutzer'),
  },
  type: 'text',
  renderer: 'farmdok.optional',
  width: 200,
  readOnly: true,
  renderEmpty,
};

export const totalFieldSize: ColumnSettingsOptional = {
  data: 'fieldSize',
  header: {
    title: () => Vue.i18n.translate('Feldgröße'),
    description: () => Vue.i18n.translate('Gesamtfläche in ha'),
  },
  type: 'numeric',
  renderer: 'farmdok.optional',
  width: 100,
  hidden: true,
  renderEmpty,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const taskNumber: Handsontable.ColumnSettings = {
  data: 'TODO',
  header: {
    title: () => Vue.i18n.translate('Auftragsnummer'),
  },
  hidden: true,
  // renderEmpty,
};

export const tableColumns: FarmdokColumnSettings[] = [
  id,
  storeStatusColumn,
  selectColumn,
  expandColumn,
  ruleCheckStatus,
  company,
  date,
  activityTypeColumn,
  fieldColumn,
  processedArea,
  products,
  equipment,
  workingTime,
  drivingTime,
  setupTime,
  pauseTime,
  userComment,
  user,
  totalFieldSize,
  // taskNumber,
  placeholderColumn,
];

export default tableColumns;
