import getDisplayValue from '@/shared/modules/getDisplayValue';

import { selectColumn } from './featureSelectableRows';
import { placeholderColumn } from './tableBase';

export default {
  methods: {
    export(ids) {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'data';
      input.value = this.exportBuildJson(ids);
      const form = document.createElement('form');
      form.method = 'POST';
      form.action = `${process.env.VUE_APP_ORIGIN}/admin/reports/generate/xls`;
      form.target = '_blank';
      form.appendChild(input);
      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    },
    exportBuildJson(ids) {
      const lut = [
        { bold: true }, // header
        { fontSize: 10 }, // subheader
        { format: 'default' },
        { format: 'numeric' },
        { format: 'datetime' },
      ];
      const cellsMeta = [];
      const columnTitles = [];
      const columnDescriptions = [];
      const columnWidths = [];
      this.tableSettingsComputed.columns.forEach((column, physicalCol) => {
        if (
          column.key === selectColumn.key ||
          column.key === placeholderColumn.key ||
          this.hot.getPlugin('hiddenColumns').isHidden(physicalCol)
        ) {
          return;
        }
        const visualCol = this.hot.toVisualColumn(physicalCol);
        columnWidths.push(this.hot.getColWidth(visualCol));
        columnTitles.push(typeof column.header.title === 'function' ? column.header.title() : '');
        columnDescriptions.push(typeof column.header.description === 'function' ? column.header.description() : '');
        const colMeta = [0, 1];
        let lutIndex = 2;
        if (column.type === 'numeric') {
          lutIndex = 3;
        } else if (['date', 'datetime'].includes(column.type)) {
          lutIndex = 4;
        }
        ids.forEach(() => {
          colMeta.push(lutIndex);
        });
        cellsMeta.push(colMeta);
      });

      const data = [];
      data.push(columnTitles);
      data.push(columnDescriptions);
      ids.forEach((id) => {
        const rowData = [];
        this.tableSettingsComputed.columns.forEach((column, physicalCol) => {
          if (
            column.key === selectColumn.key ||
            column.key === placeholderColumn.key ||
            this.hot.getPlugin('hiddenColumns').isHidden(physicalCol)
          ) {
            return;
          }
          if (column.type === 'numeric') {
            rowData.push(parseFloat(getDisplayValue(this.tableData[id], column).replace(',', '.')));
          } else {
            rowData.push(getDisplayValue(this.tableData[id], column));
          }
        });
        data.push(rowData);
      });

      const pages = [
        {
          sheetSettings: {},
          sheetName: 'Sheet 1',
          metadata: { cells: cellsMeta, lut },
          data,
          columnWidths,
          rowHeights: [],
          images: [],
        },
      ];
      return JSON.stringify(pages);
    },
  },
};
