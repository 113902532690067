import Vue from 'vue';

import { placeholderColumn } from '@/shared/handsontable/mixins/tableBase';

import { amount, fieldsTotalSize, totalStarchAmount } from './columns';

export const tableImportTotalYields = [
  {
    ...fieldsTotalSize,
    lockedVisibility: true,
  },
  {
    ...amount,
    key: 'reinekartoffel',
    header: {
      title: () => Vue.i18n.translate('Erntemenge gesamt (t)'),
      description: () => Vue.i18n.translate('t'),
    },
    width: 200,
    readOnly: true,
    lockedVisibility: true,
  },
  {
    ...totalStarchAmount,
    lockedVisibility: true,
  },
  {
    ...placeholderColumn,
    hiddenInPageSettings: true,
    lockedVisibility: true,
    lockedPosition: true,
  },
];

export default tableImportTotalYields;
