<template>
  <div class="table-loading">
    <div class="table-loading__header">
      <div
        v-for="column in columnsComputed"
        class="table-loading__header-content"
        :key="`header_${column.key}`"
        :style="column.width ? `flex: 0 1 ${column.width}px;` : ''"
      >
        {{ column.header != null ? column.header.title() : '' }}
      </div>
    </div>
    <div v-for="row in rows" class="table-loading__row" :key="row">
      <div
        v-for="column in columnsComputed"
        class="table-loading__row-content"
        :key="`${row}_${column.key}`"
        :style="column.width ? `flex: 0 1 ${column.width}px` : ''"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableLoading',
  props: {
    columns: {
      type: Array,
      default: null,
    },
  },
  computed: {
    rows() {
      return [...new Array(5).keys()];
    },
    columnsComputed() {
      return this.columns
        .filter((column) => column != null && !column.hiddenPerDefault)
        .map((column) => ({
          key: column.key ?? column.data,
          header: column.header,
          width: typeof column.width === 'number' ? column.width : null,
        }))
        .slice(0, 7);
    },
  },
};
</script>

<style scoped lang="scss">
.table-loading__header,
.table-loading__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  border-bottom: 1px solid var(--medium);
  background: var(--lightest);
  font-size: 12px;
  font-weight: 600;
  column-gap: var(--spacer_4);
}

.table-loading__header-content,
.table-loading__row-content {
  flex: 1 1 0px;
}

.table-loading__row {
  display: flex;
  height: 43px;
  background-color: var(--white);
  padding: var(--spacer_2) 0;
}

.table-loading__row-content {
  height: 100%;
  background: var(--lightest);

  &:first-child {
    border-left: var(--spacer_2) solid var(--white);
  }
  &:last-child {
    border-right: var(--spacer_2) solid var(--white);
  }
}
</style>
