
import { PropType, defineComponent } from 'vue';

import ResultTable from '@/precision-farming/application-maps/components/resultTable/ResultTable.vue';
import { ColumnSettings, TableData } from '@/precision-farming/application-maps/components/resultTable/types';
import { FertilizationNutrients } from '@/precision-farming/application-maps/fertilization/store/types';

export default defineComponent({
  name: 'NutrientAmountTable',
  components: {
    ResultTable,
  },
  props: {
    nutrients: {
      type: Object as PropType<FertilizationNutrients>,
      required: true,
    },
    nutrientAmounts: {
      type: Object as PropType<FertilizationNutrients | null>,
    },
  },
  data() {
    const columns: ColumnSettings[] = [
      {
        key: 'placeholder',
        header: {
          title: () => '',
        },
        type: 'text',
      },
      {
        key: 'n',
        header: {
          title: () => 'N',
        },
        type: 'numberInput',
        width: 60,
      },
      {
        key: 'p',
        header: {
          title: () => 'P₂O₅',
        },
        type: 'numberInput',
        width: 60,
      },
      {
        key: 'k',
        header: {
          title: () => 'K₂O',
        },
        type: 'numberInput',
        width: 60,
      },
    ];
    return {
      columns,
    };
  },
  computed: {
    tableData(): TableData[] {
      const lines: TableData[] = [];
      lines.push({
        placeholder: 'NPK in %',
        // map nutrients to table data of type Input,
        n: {
          value: this.nutrients.n,
        },
        p: {
          value: this.nutrients.p,
        },
        k: {
          value: this.nutrients.k,
        },
      });
      if (this.nutrientAmounts) {
        lines.push({
          placeholder: this.$t('Menge in kg/ha') || 'Menge in kg/ha',
          n: {
            value: this.nutrientAmounts.n,
            disabled: this.nutrients.n === 0,
          },
          p: {
            value: this.nutrientAmounts.p,
            disabled: this.nutrients.p === 0,
          },
          k: {
            value: this.nutrientAmounts.k,
            disabled: this.nutrients.k === 0,
          },
        });
      }
      return lines;
    },
  },
  methods: {
    updateNutrient(key: keyof FertilizationNutrients, value: number | null) {
      this.$emit('update:nutrient', key, value);
    },
    updateNutrientAmount(key: string, value: number | null) {
      this.$emit('update:nutrientAmount', key, value);
    },
    onInput({ key, rowIndex, value }: { key: string; rowIndex: number; value: number }) {
      const percentageRow = 0;
      if (rowIndex === percentageRow) {
        this.updateNutrient(key as keyof FertilizationNutrients, value ?? null);
      } else {
        this.updateNutrientAmount(key as keyof FertilizationNutrients, value ?? null);
      }
    },
  },
});
