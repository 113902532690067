import Vue from 'vue';

import '../renderers/FarmNameRenderer';
import '../renderers/PartnerStatusRenderer';

export const partnerNumber = {
  key: 'partnerNumber',
  header: {
    title: () => Vue.i18n.translate('Partner-Nummer'),
    description: () => Vue.i18n.translate('Partner-Nummer, Kunden-Nummer, CRM Id'),
  },
  type: 'text',
  width: 170,
  readOnly: true,
};

export const farmNumber = {
  key: 'farmNumber',
  header: {
    title: () => Vue.i18n.translate('Betriebsnummer'),
    description: () => Vue.i18n.translate('Betriebsnummer des Sender-Betriebs'),
  },
  type: 'text',
  width: 120,
  readOnly: true,
};

export const farmName = {
  key: 'farmName',
  header: {
    title: () => Vue.i18n.translate('Betriebsname'),
    description: () => Vue.i18n.translate('Name des Sender-Betriebs'),
  },
  type: 'text',
  required: false,
  className: 'text-bold',
  width: 350,
  readOnly: true,
};

export const farmNameWithStatus = {
  ...farmName,
  type: 'farmName',
  readOnly: false,
  getStatus(visualRow) {
    if (typeof this.visualRowToGuid !== 'function' || !this.tableData) {
      return null;
    }
    const id = this.visualRowToGuid(visualRow);
    const company = this.tableData[id];
    if (!company) {
      return null;
    }
    return company.status;
  },
  onClick(args) {
    this.$emit('click:status', args);
  },
};

export const company = {
  key: 'companyId',
  header: {
    title: () => Vue.i18n.translate('Betriebsname'),
    description: () => Vue.i18n.translate('Name des Sender-Betriebs'),
  },
  type: 'dropdown',
  required: true,
  placeholder: Vue.i18n.translate('Betrieb wählen'),
  width: 350,
};

export const fieldCount = {
  key: 'fieldCount',
  header: {
    title: () => Vue.i18n.translate('Anzahl Felder'),
    description: () => Vue.i18n.translate('Gesamtanzahl der bereitgestellten Felder'),
  },
  type: 'numeric',
  required: false,
  width: 70,
  readOnly: true,
};

export const fieldSize = {
  key: 'fieldSize',
  header: {
    title: () => Vue.i18n.translate('Gesamtfläche'),
    description: () => Vue.i18n.translate('Gesamtfläche in ha'),
  },
  type: 'numeric',
  required: false,
  width: 100,
  readOnly: true,
};

export const crops = {
  key: 'crops',
  header: {
    title: () => Vue.i18n.translate('Kultur'),
  },
  type: 'text',
  required: false,
  width: 270,
  readOnly: true,
  data(entry) {
    if (!entry) {
      return '';
    }
    return entry.crops.join(', ');
  },
};

export const crop = {
  key: 'fieldCropName',
  header: {
    title: () => Vue.i18n.translate('Kultur'),
  },
  type: 'text',
  width: 170,
  readOnly: true,
};

export const varieties = {
  key: 'varieties',
  header: {
    title: () => Vue.i18n.translate('Sorte'),
  },
  type: 'text',
  required: false,
  width: 270,
  readOnly: true,
  data(entry) {
    if (entry == null) {
      return '';
    }
    return entry.varieties.join(', ');
  },
};

export const variety = {
  key: 'fieldVarietyName',
  header: {
    title: () => Vue.i18n.translate('Sorte'),
  },
  type: 'text',
  width: 170,
  readOnly: true,
};

export const lastUpdate = {
  key: 'lastUpdate',
  header: {
    title: () => Vue.i18n.translate('Zuletzt aktualisiert'),
  },
  type: 'date',
  required: false,
  width: 120,
  readOnly: true,
};

export const status = {
  key: 'status',
  header: {
    title: () => Vue.i18n.translate('Status'),
    description: () => Vue.i18n.translate('Partner-Status'),
  },
  type: 'partnerStatus',
  onClick(args) {
    this.$emit('click:status', args);
  },
  required: false,
  width: 120,
  readOnly: true,
};

export const contactEmail = {
  key: 'contactEmail',
  header: {
    title: () => Vue.i18n.translate('Kontakt E-Mail'),
    description: () => Vue.i18n.translate('E-mail des Users, der freigegeben hat'),
  },
  type: 'text',
  requred: false,
  width: 170,
  readOnly: true,
};

export const fieldGroupNumber = {
  key: 'fieldGroupNumber',
  header: {
    title: () => Vue.i18n.translate('FSNr'),
    description: () => Vue.i18n.translate('Feldstück-Nummer'),
  },
  type: 'text',
  width: 70,
  readOnly: true,
};

export const fieldNumber = {
  key: 'fieldNumber',
  header: {
    title: () => Vue.i18n.translate('Feld Nr'),
    description: () => Vue.i18n.translate('Feld-Nummer'),
  },
  type: 'text',
  width: 70,
  readOnly: true,
};

export const fieldName = {
  key: 'fieldName',
  header: {
    title: () => Vue.i18n.translate('Name'),
  },
  type: 'text',
  placeholder: Vue.i18n.translate('<Bezeichnung>'),
  width: 270,
  readOnly: true,
};

export const fileName = {
  key: 'fileName',
  header: {
    title: () => Vue.i18n.translate('Dateiname'),
  },
  type: 'text',
  width: 270,
  readOnly: true,
};

export const fileLink = {
  key: 'fileLink',
  header: {
    title: () => Vue.i18n.translate('Link'),
    description: () => Vue.i18n.translate('Link zur Datei'),
  },
  type: 'text',
  width: 270,
  readOnly: false,
};

export const uploadDate = {
  key: 'uploadDate',
  header: {
    title: () => Vue.i18n.translate('Hochgeladen'),
    description: () => Vue.i18n.translate('Datum an dem die Datei hochgeladen wurde'),
  },
  type: 'date',
  width: 120,
  readOnly: true,
};

export const ndvi = {
  key: 'ndvi',
  header: {
    title: () => Vue.i18n.translate('NDVI'),
    description: () => Vue.i18n.translate('Durchschnittlicher NDVI-Index'),
  },
  type: 'numeric',
  width: 85,
  readOnly: true,
};
