import axios from 'axios';
import Vue from 'vue';

import '@/initI18n';

import PageApplicationMapsSpraying from '@/precision-farming/application-maps/spraying/PageApplicationMapsSpraying.vue';
import LoadingGlobal from '@/shared/components/LoadingGlobal.vue';
import { baseUrl } from '@/shared/constants';
import kebabCaseToCamelCase from '@/shared/modules/kebabCaseToCamelCase';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import store from '@/store';

import PageApplicationMapsUseCaseSelection from './application-maps/PageApplicationMapsUseCaseSelection.vue';
import PageApplicationMapsFertilization from './application-maps/fertilization/PageApplicationMapsFertilization.vue';
import PageApplicationMapsFertilizationCereals from './application-maps/fertilizationCereals/PageApplicationMapsFertilizationCereals.vue';
import PageApplicationMapsSeeding from './application-maps/seeding/PageApplicationMapsSeeding.vue';
import PageMonitoring from './monitoring/PageMonitoring.vue';

export default [
  {
    path: `${baseUrl}precision-farming/monitoring`,
    name: 'monitoring',
    component: PageMonitoring,
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Vegetationsmonitoring'),
      featureControl: availableFeatures.FEATURE_VEGETATION_MONITORING,
    },
  },
  {
    path: `${baseUrl}precision-farming/application-maps`,
    name: 'application-maps',
    component: PageApplicationMapsUseCaseSelection,
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Applikationskarten'),
      featureControl:
        availableFeatures.FEATURE_APPLICATION_MAPS_FERTILIZATION_FD_BASIC ||
        availableFeatures.FEATURE_APPLICATION_MAPS_SEEDING_SAATBAU_MAIZE ||
        availableFeatures.FEATURE_APPLICATION_MAPS_TERRAZO,
    },
  },
  {
    path: `${baseUrl}precision-farming/application-maps/:workflowKey/task/:taskId`,
    name: 'application-maps__task',
    component: LoadingGlobal,
    beforeEnter: async (to, from, next) => {
      const { taskId, workflowKey } = to.params;
      try {
        const { data } = await axios.get(`/admin/taskplan/get/${taskId}?version=2.0`);
        if (data != null && data.status === 'success' && data.data != null) {
          const workFlowStoreKey = kebabCaseToCamelCase(workflowKey);
          await store.dispatch(`precisionFarming/applicationMaps/${workFlowStoreKey}/setTask`, data.data);
          next({ name: `application-maps/workflows/${workflowKey}` });
        } else {
          next({ name: `application-maps/workflows/${workflowKey}` });
        }
      } catch (e) {
        console.error('Unable to set task for application maps.', e);
        next({ name: 'application-maps' });
      }
    },
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Applikationskarten'),
      featureControl:
        availableFeatures.FEATURE_APPLICATION_MAPS_FERTILIZATION_FD_BASIC ||
        availableFeatures.FEATURE_APPLICATION_MAPS_SEEDING_SAATBAU_MAIZE ||
        availableFeatures.FEATURE_APPLICATION_MAPS_TERRAZO,
    },
  },

  {
    path: `${baseUrl}precision-farming/application-maps/workflows/fertilization`,
    name: 'application-maps/workflows/fertilization',
    component: PageApplicationMapsFertilization,
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Düngekarte'),
      featureControl: availableFeatures.FEATURE_APPLICATION_MAPS_FERTILIZATION_FD_BASIC,
    },
  },

  {
    path: `${baseUrl}precision-farming/application-maps/workflows/seeding`,
    name: 'application-maps/workflows/seeding',
    component: PageApplicationMapsSeeding,
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Saatbau Mais Aussaatkarte'),
      featureControl: availableFeatures.FEATURE_APPLICATION_MAPS_SEEDING_SAATBAU_MAIZE,
    },
  },

  {
    path: `${baseUrl}precision-farming/application-maps/workflows/fertilization-cereals`,
    name: 'application-maps/workflows/fertilization-cereals',
    component: PageApplicationMapsFertilizationCereals,
    meta: {
      requiresAuth: true,
      label: () => `Terrazo ${Vue.i18n.translate('Düngekarte für Getreide')}`,
      featureControl: availableFeatures.FEATURE_APPLICATION_MAPS_TERRAZO,
    },
  },
  {
    path: `${baseUrl}precision-farming/application-maps/workflows/spraying`,
    name: 'application-maps/workflows/spraying',
    component: PageApplicationMapsSpraying,
    meta: {
      requiresAuth: true,
      label: () => Vue.i18n.translate('Variable Pflanzenschutzkarte'),
      featureControl: availableFeatures.FEATURE_APPLICATION_MAPS_SPRAYING_FD_BASIC,
    },
  },
];
