import merge from 'lodash.merge';

import { ENTRY_NEW, ENTRY_REMOVED, ERROR_FETCHING, GUID_KEY, HOT_LICENSE_KEY } from '@/shared/constants';
import { comparatorAlphaNumericEmptyLast } from '@/shared/modules/comparators';
import getDisplayValue, { getDescendantProp } from '@/shared/modules/getDisplayValue';

import { selectColumn } from './featureSelectableRows';

export const guidColumn = {
  key: GUID_KEY,
  type: 'text',
  hiddenPerDefault: true,
  hiddenInPageSettings: true,
  lockedVisibility: true,
  lockedPosition: true,
};

export const placeholderColumn = {
  // Handsontable settings
  type: 'text',
  width: 190,
  readOnly: true,
  // TableBase settings
  key: '__placeholder',
  data: () => null,
  hiddenInPageSettings: true,
  lockedVisibility: true,
  lockedPosition: true,
  noHeaderContextMenu: true,
};

export default {
  props: {
    /**
     * All the settings that will be forwarded to the handsontable.
     * Checkout https://handsontable.com/docs/
     */
    tableSettings: {
      type: Object,
      default: null,
    },
    /**
     * List of objects that will be rendered one row per entry.
     * Usually mapped to store data.
     * Entry keys will be mapped to column keys.
     * Required key for every entry:
     *   - GUID_KEY
     * Additional keys for entries:
     *   - storeStatus
     */
    tableData: {
      type: Object,
      default: null,
    },
    /**
     * If set to true all cells will be rendered using the LoadingRenderer.
     * After updating this prop the handsontable data will be reset.
     * If you are not using the loading prop call tableBase.setData() manually from the container component whenever you want to update
     * the handsontable table data.
     */
    loading: {
      type: Boolean,
      default: false,
    },
    /**
     * List of all errors.
     * An error can have the following keys:
     *  - key (column key)
     *  - guid (entry guid)
     *  - type (check src/shared/constants.js)
     */
    tableErrors: {
      type: Array,
      default: null,
    },
    /**
     * Use to extend cellProperty. Contrary to handsontable cells the third parameter will be the already
     * computed cellProperties that you can overwrite.
     *
     * https://handsontable.com/docs/8.1.0/Options.html#cells
     */
    cells: {
      type: Function,
      default: (physicalRow, physicalCol, cellProperties) => cellProperties,
    },
    /**
     * Sets all columns to read-only and disables the context menu.
     */
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hot: null,
      hotHooks: [],
      tableDataInternal: {},
      tablePhysicalRowByGuid: {},
      tableGuidByPhysicalRow: [],
      tableHooks: {
        setData: [],
        property: [],
        cells: [],
        staticHeight: [],
        comparator: [comparatorAlphaNumericEmptyLast],
        colHeaders: [],
      },
      tableSettingsInternal: {
        selectionMode: 'single',
        persistentState: true,
        manualColumnResize: true,
        manualColumnMove: true,
        hiddenColumns: {
          indicators: false,
        },
        columnSorting: {
          indicator: true,
          sortEmptyCells: true,
          headerAction: true,
          compareFunctionFactory: this.compareFunctionFactory,
        },
        licenseKey: HOT_LICENSE_KEY,
        rowHeights: 40,
        outsideClickDeselects: true,
        stretchH: 'last',
        cells: (...args) => this.cellsInternal(...args),
        colHeaders: (...args) => this.colHeaders(...args),
        afterGetColHeader: (...args) => this.afterGetColHeader(...args),
        afterColumnResize: (...args) => this.afterColumnResize(...args),
        beforeColumnMove: (...args) => this.beforeColumnMove(...args),
        afterColumnMove: (...args) => this.afterColumnMove(...args),
        columns: [],
      },
      // Use a copy of the tableSettings for handsontable as it would wipe the data if handsontable uses the computed property directly
      // https://git.farmdok.com/farmdok/app-webclient/-/issues/567#note_98732
      tableSettingsForHot: {},
      tableErrorsInternal: [],
    };
  },
  computed: {
    noContent() {
      return (
        !this.loading &&
        !this.fetchingError &&
        Object.keys(this.tableDataComputed).length === 0 &&
        this.$slots['no-content'] != null
      );
    },
    noSearchResults() {
      return (
        !this.loading &&
        !this.fetchingError &&
        !this.noContent &&
        Object.keys(this.tableDataComputed).length > 0 &&
        this.$slots['no-search-results'] != null &&
        this.visibleRows.length === 0
      );
    },
    fetchingError() {
      if (this.tableErrorsComputed.length === 0) {
        return false;
      }
      return this.tableErrorsComputed.some((error) => error.type === ERROR_FETCHING);
    },
    tableSettingsComputed() {
      const tableSettings = merge({}, this.tableSettingsInternal, this.tableSettings);
      tableSettings.columns = tableSettings.columns.map(this.setupColumn);
      return tableSettings;
    },
    /**
     * tableData should always be set by props.
     *
     * @deprecated
     */
    tableDataComputed() {
      if (this.tableData != null) {
        return this.tableData;
      }
      return this.tableDataInternal;
    },
    /**
     * tableErrors should always be set by props.
     *
     * @deprecated
     */
    tableErrorsComputed() {
      if (Array.isArray(this.tableErrors)) {
        return this.tableErrors;
      }
      return this.tableErrorsInternal;
    },
  },
  created() {
    this.tableSettingsForHot = this.tableSettingsComputed;
  },
  mounted() {
    if (process.env.VUE_APP_EXPOSE === '1') {
      window.FARMDOK.tables[this.id] = this;
    }
    this.addHotHook('afterScrollVertically', () => this.$emit('afterScrollVertically'));
    this.addHotHook('afterScrollHorizontally', () => this.$emit('afterScrollHorizontally'));
    // workaround - hot.loadData() resets states (https://handsontable.com/docs/api/core/#loaddata)
    this.addHotHook('afterLoadData', this.restoreFromPersistentState);
  },
  beforeDestroy() {
    delete window.FARMDOK.tables[this.id];
  },
  methods: {
    addHotHook(key, callback) {
      this.hotHooks.push({ key, callback });
    },
    async tableMounted() {
      await this.$nextTick();
      if (this.$refs.table != null && this.$refs.table.hotInstance != null) {
        this.hot = this.$refs.table.hotInstance;
      } else if (
        // WORKAROUND : until https://forum.handsontable.com/t/typeerror-cannot-convert-a-symbol-value-to-a-string/3768 is resolved
        this.$refs.table != null &&
        Array.isArray(this.$refs.table.$children) &&
        this.$refs.table.$children.length === 1 &&
        this.$refs.table.$children[0] != null &&
        this.$refs.table.$children[0].hotInstance != null
      ) {
        this.hot = this.$refs.table.$children[0].hotInstance;
      }
      if (this.hot == null) {
        return;
      }
      this.hotHooks.forEach(({ key, callback }) => this.hot.addHook(key, callback));
      await this.$nextTick();
      this.$emit('tableMounted');
      this.setData();
    },
    tableDestroyed() {
      this.hot = null;
    },
    /**
     * Binds all column methods to this.<br>
     * Adds data() method if it does not already contain one.<br>
     * Adds custom compare factory so that the column can resolve the renderer value for sorting.
     *
     * @private
     * @param column
     * @returns {object}
     */
    setupColumn(column) {
      const newColumn = { ...column };
      Object.keys(newColumn).forEach((key) => {
        if (typeof column[key] === 'function') {
          newColumn[key] = (...args) => column[key].call(this, ...args);
        }
      });
      if (column.data == null) {
        newColumn.data = (...args) => this.property(column.key)(...args);
      }
      newColumn.columnSorting = {
        compareFunctionFactory: (sortOrder) => this.compareFunctionFactory(sortOrder, newColumn),
      };
      if (this.readOnly && column.key !== selectColumn.key) {
        newColumn.readOnly = true;
      }
      return newColumn;
    },
    /**
     * WORKAROUND
     *
     * This func loads settings from localStorage and updates the following plugins:
     *  - manualColumnMove
     *  - hiddenColumns
     *  - columnSorting
     * This is done initially by persistentState plugin but gets reset
     * when we call hot.loadData()
     */
    restoreFromPersistentState() {
      if (this.hot == null || this.hot.isDestroyed) {
        return;
      }

      // check manualColumnMove
      const responseManualColumnMove = {};
      this.hot.getPlugin('persistentState').loadValue('manualColumnMove', responseManualColumnMove);
      if (Array.isArray(responseManualColumnMove.value)) {
        let start = 0;
        let end = responseManualColumnMove.value.length;
        if (typeof this.tableSettings.fixedColumnsLeft === 'number') {
          start = this.tableSettings.fixedColumnsLeft;
        }
        if (
          this.tableSettingsComputed.columns[this.tableSettingsComputed.columns.length - 1].key ===
          placeholderColumn.key
        ) {
          end -= 1;
        }
        const columns = responseManualColumnMove.value.slice(start, end);
        this.hot.getPlugin('manualColumnMove').moveColumns(columns, start);
      }

      // check hiddenColumns
      const hiddenColumns = {
        indicators: false,
        columns: [],
      };
      const responseHiddenColumns = {};
      this.hot.getPlugin('persistentState').loadValue('hiddenColumns', responseHiddenColumns);
      if (Array.isArray(responseHiddenColumns.value)) {
        hiddenColumns.columns = responseHiddenColumns.value;
      } else if (
        this.tableSettingsComputed.hiddenColumns != null &&
        Array.isArray(this.tableSettingsComputed.hiddenColumns.columns)
      ) {
        hiddenColumns.columns = this.tableSettingsComputed.hiddenColumns.columns;
      }
      this.hot.updateSettings({ hiddenColumns });

      // check columnSorting
      this.$nextTick(this.restoreSortFromPersistentState);
    },
    /**
     * WORKAROUND
     *
     * Triggers a re-sorting of the table data.
     * As columnSorting.sort() would remove the sorting we need to get the current setting form persistentState first.
     */
    restoreSortFromPersistentState() {
      if (this.hot == null) {
        return;
      }
      let sort;
      const responseColumnSorting = {};
      this.hot.getPlugin('persistentState').loadValue('columnSorting', responseColumnSorting);
      if (responseColumnSorting.value != null) {
        sort = responseColumnSorting.value.initialConfig;
      } else if (
        this.tableSettingsComputed.columnSorting != null &&
        this.tableSettingsComputed.columnSorting.initialConfig != null
      ) {
        sort = this.tableSettingsComputed.columnSorting.initialConfig;
      }
      this.hot.getPlugin('columnSorting').sort(sort);
    },
    /**
     * Re-initialized the data in the handsontable.<br>
     * Is automatically called when the handsontable is mounted.
     */
    async setData() {
      this.tableSettingsForHot = this.tableSettingsComputed;
      await this.$nextTick();
      this.tablePhysicalRowByGuid = {};
      this.tableGuidByPhysicalRow = [];
      const data = Object.values(this.tableDataComputed).filter(
        (entry) => entry.storeStatus !== ENTRY_NEW && entry.storeStatus !== ENTRY_REMOVED,
      );
      data.forEach((entry, physicalRow) => {
        const guid = entry[GUID_KEY];
        this.tablePhysicalRowByGuid[guid] = physicalRow;
        this.tableGuidByPhysicalRow[physicalRow] = guid;
      });
      this.tableHooks.setData.forEach((callback) => callback());
      this.hot.loadData(data);
    },
    hotRender() {
      if (this.hot == null) {
        return;
      }
      this.hot.render();
    },
    property(columnKey) {
      return (entry) => {
        if (entry == null) {
          return null;
        }
        const response = this.tableHooks.property.reduce(
          (currentResponse, callback) => callback(entry, columnKey, currentResponse),
          null,
        );
        if (response != null) {
          return response;
        }

        if (entry[GUID_KEY] != null && this.tableDataComputed[entry[GUID_KEY]] != null) {
          return getDescendantProp(this.tableDataComputed[entry[GUID_KEY]], columnKey);
        }

        return getDescendantProp(entry, columnKey);
      };
    },
    visualRowToGuid(visualRow) {
      if (this.hot == null) {
        return null;
      }
      const physicalRow = this.hot.toPhysicalRow(visualRow);
      return this.physicalRowToGuid(physicalRow);
    },
    physicalRowToGuid(physicalRow) {
      return this.tableGuidByPhysicalRow[physicalRow];
    },
    guidToPhysicalRow(guid) {
      return this.tablePhysicalRowByGuid[guid];
    },
    guidToVisualRow(guid) {
      if (this.hot == null) {
        return null;
      }
      const physicalRow = this.guidToPhysicalRow(guid);
      return this.hot.toVisualRow(physicalRow);
    },
    getColumnByKey(key) {
      const physicalCol = this.getPhysicalColByKey(key);
      if (physicalCol == null) {
        return null;
      }
      return this.tableSettingsComputed.columns[physicalCol];
    },
    getPhysicalColByKey(key) {
      let physicalCol = null;
      this.tableSettingsComputed.columns.some((column, index) => {
        if (column.key === key) {
          physicalCol = index;
          return true;
        }
        return false;
      });
      return physicalCol;
    },
    // Handsontable Hooks
    cellsInternal(physicalRow, physicalCol) {
      let cellProperties = {};
      if (this.tableSettingsComputed.columns == null || this.tableSettingsComputed.columns[physicalCol] == null) {
        return cellProperties;
      }
      cellProperties.readOnly = this.tableSettingsComputed.columns[physicalCol].readOnly;
      cellProperties.renderer = this.tableSettingsComputed.columns[physicalCol].renderer;
      cellProperties.getDisplayValue = (value) => {
        const column = this.tableSettingsComputed.columns[physicalCol];
        if (column == null) {
          return '';
        }
        return getDisplayValue({ [column.key]: value }, column);
      };
      cellProperties = this.tableHooks.cells.reduce(
        (currentProps, callback) => callback(physicalRow, physicalCol, currentProps),
        cellProperties,
      );
      cellProperties = this.cells(physicalRow, physicalCol, cellProperties);
      return cellProperties;
    },
    colHeaders(physicalCol) {
      const column = this.tableSettingsComputed.columns[physicalCol];
      if (column == null) {
        return '';
      }
      const colHeader = this.tableHooks.colHeaders.reduce(
        (currentColHeader, callback) => callback(column, currentColHeader),
        null,
      );
      if (colHeader != null) {
        return colHeader;
      }
      if (column.header != null && typeof column.header.title() === 'string') {
        return `<span class="colHeaderInner">${column.header.title()}</span>`;
      }
      return '';
    },
    afterGetColHeader(visualCol, th) {
      if (this.hot == null) {
        return;
      }

      const physicalCol = this.hot.toPhysicalColumn(visualCol);
      const column = this.tableSettingsComputed.columns[physicalCol];

      if (column != null && column.type === 'checkbox') {
        th.classList.add('checkbox');
      }
    },
    compareFunctionFactory(sortOrder, column) {
      let direction = 1;
      if (sortOrder === 'desc') {
        direction = -1;
      }
      return (value, nextValue) => {
        let rendererValue = value;
        let nextRendererValue = nextValue;
        if (
          column != null &&
          ['dropdown', 'fieldStatus', 'fieldGroupArchived', 'sharedFieldInfo'].includes(column.type)
        ) {
          rendererValue = getDisplayValue({ [column.key]: value }, column);
          nextRendererValue = getDisplayValue({ [column.key]: nextValue }, column);
        }
        if (column != null && ['subtable'].includes(column.type)) {
          const { 0: firstValue } = value;
          const { 0: firstNextValue } = nextValue;
          rendererValue = firstValue;
          nextRendererValue = firstNextValue;
        }
        return this.tableHooks.comparator.reduce(
          (lastSort, callback) => callback(rendererValue, nextRendererValue, direction, lastSort),
          0,
        );
      };
    },
    afterColumnResize(newSize, visualCol) {
      const physicalCol = this.hot.toPhysicalColumn(visualCol);
      let minSize = 70;
      if (this.tableSettingsComputed.columns[physicalCol].type === 'checkbox') {
        minSize = 40;
      } else if (this.tableSettingsComputed.columns[physicalCol].key === placeholderColumn.key) {
        minSize = placeholderColumn.width;
      }
      if (newSize < minSize) {
        this.hot.getPlugin('manualColumnResize').setManualSize(visualCol, minSize);
        this.hotRender();
      }
    },
    beforeColumnMove(movedColumns, finalIndex) {
      if (!this.hot) {
        return true;
      }
      if (typeof this.tableSettings.fixedColumnsLeft !== 'number') {
        return true;
      }
      // do not allow the fixed columns to be moved
      if (
        movedColumns.some((visualColumn) => {
          if (visualColumn < this.tableSettings.fixedColumnsLeft) {
            return true;
          }
          const physicalCol = this.hot.toPhysicalColumn(visualColumn);
          return (
            this.tableSettingsComputed.columns == null ||
            this.tableSettingsComputed.columns[physicalCol] == null ||
            this.tableSettingsComputed.columns[physicalCol].key === placeholderColumn.key
          );
        })
      ) {
        return false;
      }
      // do not allow to move a column into the fixed column
      return finalIndex >= this.tableSettings.fixedColumnsLeft;
    },
    afterColumnMove(movedColumns, finalIndex, dropIndex, movePossible, orderChanged) {
      // only update updatePersistentState after user manually moved the columns directly in the table
      if (dropIndex != null && orderChanged) {
        this.updatePersistentState();
      }
    },
    getColumnsForPageSettings() {
      let hiddenColumns = null;
      if (this.hot != null) {
        hiddenColumns = this.hot
          .getPlugin('hiddenColumns')
          .getHiddenColumns()
          .map((visualColumn) => this.hot.toPhysicalColumn(visualColumn));
      }
      return this.tableSettingsComputed.columns
        .map((column, physicalColumn) => ({
          ...column,
          physicalColumn,
          visualColumn: this.hot != null ? this.hot.toVisualColumn(physicalColumn) : physicalColumn,
          fixed: physicalColumn < this.tableSettings.fixedColumnsLeft,
          visible: this.hot != null ? !hiddenColumns.includes(physicalColumn) : !column.hiddenPerDefault,
        }))
        .sort((a, b) => a.visualColumn - b.visualColumn);
    },
    getVisibleColumns() {
      return this.getColumnsForPageSettings().filter((col) => col.visible);
    },
    updateColumnsFromPageSettings(columns) {
      const moveColumns = columns
        .filter((column) => !column.fixed && !column.hiddenInPageSettings)
        .map((column) => this.hot.toVisualColumn(column.physicalColumn));
      this.hot.getPlugin('manualColumnMove').moveColumns(moveColumns, this.tableSettings.fixedColumnsLeft);
      this.hot.updateSettings({
        hiddenColumns: {
          indicators: false,
          columns: columns
            .filter((column) => !column.visible)
            .map((column) => this.hot.toVisualColumn(column.physicalColumn)),
        },
      });
      this.hotRender();
      this.updatePersistentState();
      this.$emit('update:tableSettings');
    },
    updatePersistentState() {
      this.hot.getPlugin('persistentState').saveValue(
        'manualColumnMove',
        this.tableSettingsComputed.columns.map((column, index) => this.hot.toPhysicalColumn(index)),
      );
      this.hot
        .getPlugin('persistentState')
        .saveValue('hiddenColumns', this.hot.getPlugin('hiddenColumns').getHiddenColumns());
    },
    validatePersistentState() {
      if (!Array.isArray(this.tableSettingsComputed.columns) || this.tableSettingsComputed.columns.length === 0) {
        return;
      }
      const checkSumCurrent = this.tableSettingsComputed.columns.reduce(
        (checkSum, column) => `${checkSum}${column.key}`,
        '',
      );
      const checkSumStored = localStorage.getItem(`${this.id}_checkSum`);
      if (checkSumCurrent === checkSumStored) {
        return;
      }
      localStorage.removeItem(`${this.id}_manualColumnMove`);
      localStorage.removeItem(`${this.id}_hiddenColumns`);
      localStorage.removeItem(`${this.id}_columnSorting`);
      localStorage.removeItem(`${this.id}__persistentStateKeys`);
      localStorage.setItem(`${this.id}_checkSum`, checkSumCurrent);
    },
  },
  watch: {
    tableSettingsComputed() {
      this.validatePersistentState();
    },
    tableErrorsComputed() {
      this.hotRender();
    },
    tableDataComputed() {
      this.hotRender();
    },
  },
};
