import Handsontable from 'handsontable';

export default function getColumnsForExport(
  columns: Handsontable.ColumnSettings[],
  subtableColumnData?: string,
): Handsontable.ColumnSettings[] {
  const columnsForExport: Handsontable.ColumnSettings[] = [];

  columns.forEach((column) => {
    if (!column.isSubtableColumn) {
      appendColumns(column, columnsForExport);
    } else if (column.isFirstSubtableColumn || column.isPartOfSubtable === subtableColumnData) {
      appendColumns(column, columnsForExport);
    }
  });
  return columnsForExport;
}

function appendColumns(column: Handsontable.ColumnSettings, columnsForExport: Handsontable.ColumnSettings[]) {
  if (column.type === 'farmdok.amountUnit') {
    columnsForExport.push(...splitAmountUnitColumn(column));
  } else {
    columnsForExport.push(column);
  }
}

function splitAmountUnitColumn(column: Handsontable.ColumnSettings) {
  if (column.type !== 'farmdok.amountUnit') throw new Error("Column is not of type 'farmdok.amountUnit'");
  const columnAmount = { ...column, type: 'numeric', header: { title: () => 'Amount' } };
  const columnUnit = { ...column, type: 'text', header: { title: () => 'Unit' } };
  return [columnAmount, columnUnit];
}
