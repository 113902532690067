<template>
  <BPopover
    v-if="computedTarget != null"
    show
    placement="bottomleft"
    custom-class="table-header-context-menu"
    :target="computedTarget"
    ref="popover"
  >
    <BForm v-click-outside="close" class="table-header-context-menu__inner" novalidate @submit.prevent="close">
      <h3 class="table-header-context-menu__title">
        {{ title }}
      </h3>
      <BButton
        v-if="!target.sortDisabled"
        class="table-header-context-menu__button"
        variant="black"
        block
        :class="target.sortOrder === 'asc' ? 'table-header-context-menu__button--active' : null"
        @click="() => sort('asc')"
      >
        <FontAwesomeIcon v-if="target.column.type !== 'numeric'" :icon="['far', 'sort-alpha-down']" />
        <span v-if="target.column.type !== 'numeric'" class="table-header-context-menu__button-text"
          ><!--
          -->{{ labelSortAscParts[0] }}
          <FontAwesomeIcon size="sm" :icon="['fal', 'arrow-right']" />
          {{ labelSortAscParts[1] }}
        </span>
        <span v-else class="table-header-context-menu__button-text"
          ><!--
          -->{{ $t('Sortieren: aufsteigend') }}
        </span>
      </BButton>
      <BButton
        v-if="!target.sortDisabled"
        class="table-header-context-menu__button"
        variant="black"
        block
        :class="target.sortOrder === 'desc' ? 'table-header-context-menu__button--active' : null"
        @click="() => sort('desc')"
      >
        <FontAwesomeIcon v-if="target.column.type !== 'numeric'" :icon="['far', 'sort-alpha-up']" />
        <span v-if="target.column.type !== 'numeric'" class="table-header-context-menu__button-text"
          ><!--
          -->{{ labelSortDescParts[0] }}
          <FontAwesomeIcon size="sm" :icon="['fal', 'arrow-right']" />
          {{ labelSortDescParts[1] }}
        </span>
        <span v-else class="table-header-context-menu__button-text"
          ><!--
          -->{{ $t('Sortieren: absteigend') }}
        </span>
      </BButton>
      <hr class="table-header-context-menu__hr" />
      <template v-if="target.column.type === 'date'">
        <FormFieldDatepicker
          :selectedDate="selectedDateFrom"
          @setSelectedDate="setSelectedDateFrom"
          :label="$t('Datum von')"
          variant="vertical"
          class="table-filter-datepicker"
          calendar-class="filter-datepicker"
          theme="light"
        />
        <FormFieldDatepicker
          :selectedDate="selectedDateTo"
          @setSelectedDate="setSelectedDateTo"
          :label="$t('Datum bis')"
          variant="vertical"
          class="table-filter-datepicker"
          calendar-class="filter-datepicker"
          theme="light"
        />
      </template>

      <BFormSelect
        class="table-header-context-menu__search-type"
        :value="filterType"
        :options="filterTypeOptions"
        @input="inputFilterType"
      />

      <label
        class="table-header-context-menu__search-label"
        :class="
          typeof filterString !== 'string' || filterString.length === 0
            ? 'table-header-context-menu__search-label--empty'
            : null
        "
      >
        <FontAwesomeIcon size="sm" :icon="['far', 'filter']" />
        <BFormInput
          class="table-header-context-menu__search-field"
          type="search"
          :class="
            typeof filterString !== 'string' || filterString.length === 0
              ? 'table-header-context-menu__search-field--empty'
              : null
          "
          :placeholder="$t('Suchbegriff eingeben ...')"
          :value="filterString"
          @input="inputFilterString"
        />
      </label>
      <hr class="table-header-context-menu__hr" />
      <h6 class="table-header-context-menu__subtitle">{{ $t('Werte:') }}</h6>
      <div class="table-header-context-menu__value-checkbox-group">
        <FormCheckbox
          class="table-header-context-menu__value-checkbox m-0"
          :checked="filterSelectedValues.length === availableValuesFiltered.length"
          :indeterminate="
            filterSelectedValues.length > 0 && filterSelectedValues.length !== availableValuesFiltered.length
          "
          @change="inputValueCheckboxAll"
        >
          <span class="table-header-context-menu__value-checkbox-inner">
            {{ $t('(Alle auswählen)') }}
          </span>
        </FormCheckbox>
        <FormFieldCheckboxGroup
          class="m-0"
          variant="no-label"
          :checked="filterSelectedValues"
          @change="inputValueCheckbox"
        >
          <FormCheckbox
            v-for="value in availableValuesFiltered"
            class="table-header-context-menu__value-checkbox"
            :key="value"
            :value="value"
          >
            <span class="table-header-context-menu__value-checkbox-inner">
              {{ typeof value !== 'string' || value === '' ? $t('(leer)') : value }}
            </span>
          </FormCheckbox>
        </FormFieldCheckboxGroup>
      </div>
      <BButton
        v-if="showClearFilter"
        class="table-header-context-menu__button"
        variant="black"
        block
        @click="clearFilter"
      >
        <span class="table-header-context-menu__button-text"
          ><!--
          -->{{ $t('Filter entfernen') }}
        </span>
      </BButton>
    </BForm>
  </BPopover>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { faFilter, faSortAlphaDown, faSortAlphaUp } from '@fortawesome/pro-regular-svg-icons';

import FormFieldDatepicker from '@/shared/components/form/FormFieldDatepicker.vue';

import FormCheckbox from '../../components/form/FormCheckbox.vue';
import FormFieldCheckboxGroup from '../../components/form/FormFieldCheckboxGroup.vue';
import { compareValuesByFilterType } from '../../modules/dataFilters';

library.add(faSortAlphaDown, faSortAlphaUp, faFilter, faArrowRight);

/**
 * Use this component to render a BPopover after the user clicks on the context menu icon in the table header.<br>
 * For correct usage add the featureTableHeaderContextMenu mixin to the table wrapper component (usually TableBase).
 *
 * @category Handsontable
 * @subcategory Molecules
 * @component
 */
export default {
  name: 'TableHeaderContextMenu',
  components: { FormFieldCheckboxGroup, FormCheckbox, FormFieldDatepicker },
  props: {
    /**
     * The target set by featureTableHeaderContextMenu.
     */
    target: {
      type: Object,
      default: null,
      validator(target) {
        if (target == null) {
          return true;
        }
        return target.column != null && target.element != null;
      },
    },
    /**
     * The current column is filtered by this type: contains, not, greater, less, equals
     * <pre>
     *   { value: 'foo', type: 'contains' }
     * </pre>>
     */
    filter: {
      type: Object,
      default: null,
    },
    /**
     * A list of all the (unique) values that exist for the current column.<br>
     * This list will be shown with checkboxes so that the user can select which values he wants to see.
     */
    availableValues: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedDateTo: null,
      selectedDateFrom: null,
      computedTarget: this.target?.element?.id || this.target?.element,
      labelSortAsc: this.$t('Sortieren: A {arrowRight} Z', { arrowRight: '#####' }),
      labelSortDesc: this.$t('Sortieren: Z {arrowRight} A', { arrowRight: '#####' }),
      filterTypeOptionsText: [
        { value: 'contains', text: this.$t('Enthält:') },
        { value: 'not', text: this.$t('Enthält nicht:') },
        { value: 'equals', text: this.$t('Ist genau:') },
      ],
      filterTypeOptionsNumber: [
        { value: 'greater', text: this.$t('Größer als:') },
        { value: 'less', text: this.$t('Kleiner als:') },
        { value: 'equals', text: this.$t('Ist genau:') },
      ],
    };
  },
  watch: {
    target: {
      handler() {
        // force reinitialization of the popover by setting the target to null and then to the new target
        this.computedTarget = null;
        this.$nextTick(() => {
          this.computedTarget = this.target?.element?.id || this.target?.element;
        });
      },
      deep: true,
    },
  },
  computed: {
    /**
     * @return {string|null}
     */
    title() {
      if (this.target == null || this.target.column == null || this.target.column.header == null) {
        return null;
      }
      return this.target.column.header.title();
    },
    /**
     * @return {array}
     */
    filterTypeOptions() {
      if (this.target.column.type === 'numeric') {
        return this.filterTypeOptionsNumber;
      }
      return this.filterTypeOptionsText;
    },
    /**
     * @return {string|null}
     */
    filterType() {
      if (this.filter != null && this.filterTypeOptions.map((type) => type.value).includes(this.filter.type)) {
        return this.filter.type;
      }
      if (this.filterTypeOptions.length > 0) {
        return this.filterTypeOptions[0].value;
      }
      return null;
    },
    /**
     * @return {string|null}
     */
    filterString() {
      if (this.filter != null) {
        return this.filter.value;
      }
      return null;
    },
    /**
     * @return {array}
     */
    filterSelectedValues() {
      if (this.filter == null || !Array.isArray(this.filter.selectedValues)) {
        return this.availableValuesFiltered;
      }
      return this.filter.selectedValues;
    },
    /**
     * @return {string}
     */
    labelSortAscParts() {
      return this.labelSortAsc.split('#####');
    },
    /**
     * @return {string}
     */
    labelSortDescParts() {
      return this.labelSortDesc.split('#####');
    },
    /**
     * @return {array}
     */
    availableValuesFiltered() {
      if (typeof this.filterString !== 'string' || this.filterString.length === 0) {
        return this.target.column.type === 'date'
          ? this.sortDates(this.availableValues)
          : [...this.availableValues].sort();
      }
      const values = this.availableValues.filter((value) => {
        if (value == null) {
          return false;
        }
        return compareValuesByFilterType(value, this.filterString, this.filterType);
      });
      return this.target.column.type === 'date' ? this.sortDates(values) : values.sort();
    },
    /**
     * @return {boolean}
     */
    showClearFilter() {
      if (typeof this.filterString === 'string' && this.filterString.length > 0) {
        return true;
      }
      if (this.filterSelectedValues.length !== this.availableValues.length) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    document.addEventListener('keyup', this.onKeyUp);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.onKeyUp);
  },
  methods: {
    resetFilterDates() {
      this.selectedDateFrom = null;
      this.selectedDateTo = null;
    },
    sortDates(dateStrings) {
      const customSort = (date1, date2) => {
        const convertToDate = (dateString) => {
          if (/\d{2}\/\d{2}\/\d{4}/.test(dateString)) {
            const [day, month, year] = dateString.split('/');
            return new Date(`${year}-${month}-${day}`);
          }

          if (/\d{2}\.\d{2}\.\d{4}/.test(dateString)) {
            const [day, month, year] = dateString.split('.');
            return new Date(`${year}-${month}-${day}`);
          }

          return null;
        };

        const date1Converted = convertToDate(date1);
        const date2Converted = convertToDate(date2);

        if (date1Converted && date2Converted) {
          return date1Converted - date2Converted;
        }

        return null;
      };

      // Sort the dates using the custom sorting function
      return dateStrings.slice().sort(customSort);
    },
    setSelectedDateFrom(date) {
      this.selectedDateFrom = date;
      this.$emit('update:dateFilter', {
        dateFrom: this.selectedDateFrom,
        dateTo: this.selectedDateTo,
      });
    },
    setSelectedDateTo(date) {
      this.selectedDateTo = date;
      this.$emit('update:dateFilter', {
        dateFrom: this.selectedDateFrom,
        dateTo: this.selectedDateTo,
      });
    },
    onKeyUp(e) {
      if (e.key === 'Escape') {
        this.close();
      }
    },
    /**
     * @private
     */
    close() {
      /**
       * User clicked outside the popover or submitted the search field.<br>
       * Should call featureTableHeaderContextMenu.tableHeaderContextMenuClose.
       *
       * @event TableHeaderContextMenu#close
       */
      this.$emit('close');
    },
    /**
     * @private
     */
    sort(sortOrder) {
      /**
       * User clicked on sort.<br>
       * sortOrder is either 'asc' or 'desc'<br>
       * Should call featureTableHeaderContextMenu.tableHeaderContextMenuSort.
       *
       * @event TableHeaderContextMenu#sort
       * @type {String} sortOrder
       */
      this.$emit('sort', sortOrder);
    },
    /**
     * @private
     */
    inputFilterType(type) {
      /**
       * User updated the filter.
       *
       * @event TableHeaderContextMenu#update:filter
       */
      this.$emit('update:filter', {
        type,
        value: this.filterString,
        selectedValues: null,
      });
    },
    /**
     * @private
     */
    inputFilterString(value) {
      /**
       * User updated the filter.
       *
       * @event TableHeaderContextMenu#update:filter
       */
      this.$emit('update:filter', {
        type: this.filterType,
        value,
        selectedValues: null,
      });
    },
    /**
     * @private
     */
    clearFilter() {
      /**
       * User updated the filter.
       *
       * @event TableHeaderContextMenu#update:filter
       */
      this.$emit('update:filter', null);
      /**
       * User clicked outside the popover or submitted the search field.<br>
       * Should call featureTableHeaderContextMenu.tableHeaderContextMenuClose.
       *
       * @event TableHeaderContextMenu#close
       */
      this.$emit('close');
    },
    /**
     * @private
     */
    inputValueCheckboxAll(checked) {
      let selectedValues = [];
      if (checked) {
        selectedValues = [...this.availableValuesFiltered];
      }
      /**
       * User updated the filter.
       *
       * @event TableHeaderContextMenu#update:filter
       */
      this.$emit('update:filter', {
        type: this.filterType,
        value: this.filterString,
        selectedValues,
      });
    },
    /**
     * @private
     */
    inputValueCheckbox(selectedValues) {
      /**
       * User updated the filter.
       *
       * @event TableHeaderContextMenu#update:filter
       */
      this.$emit('update:filter', {
        type: this.filterType,
        value: this.filterString,
        selectedValues,
      });
    },
  },
};
</script>

<style lang="scss">
.popover.table-header-context-menu {
  min-width: 200px;
  margin-top: -2px;
  margin-left: 1px;
  background: var(--black);
}

.table-header-context-menu__icon {
  position: absolute;
  top: 0;
  right: 3px;
  padding: 2px 5px;
  visibility: hidden;
  cursor: pointer;
  background-color: var(--lightest);
}

.table-header-context-menu__icon--clicked,
.table-header-context-menu__icon--filtered {
  visibility: visible;
}

.table-header-context-menu__icon-filter {
  display: none;
}

.table-header-context-menu__icon--filtered .table-header-context-menu__icon-filter {
  display: inline-block;
}

.table-header-context-menu__icon--filtered .table-header-context-menu__icon-chevron-circle-down {
  display: none;
}

th:hover .table-header-context-menu__icon {
  visibility: visible;
}

.table-header-context-menu.bs-popover-bottom > .arrow::before,
.table-header-context-menu.bs-popover-bottom > .arrow::after {
  border-bottom-color: var(--black);
}

.popover.table-header-context-menu .popover-body {
  padding: 0;
}

.table-filter-datepicker .day {
  border-radius: 6px !important;
}
.table-filter-datepicker .day:hover {
  background: var(--primary) !important;
}
.table-filter-datepicker .day.selected:hover {
  background: var(--primary) !important;
}
.table-filter-datepicker .day.selected,
.table-filter-datepicker .highlighted {
  background: var(--primary_light) !important;
}
</style>

<style scoped>
.table-header-context-menu__inner {
  font-size: 14px;
  line-height: 16px;
  color: var(--white);
}

.table-header-context-menu__hr {
  margin: 0;
  border-top: 2px solid black;
}

.table-header-context-menu__title {
  margin-bottom: 0;
  padding: 0.375rem 0.75rem;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  background: rgba(0, 0, 0, 0.3);
}

.table-header-context-menu__subtitle {
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  margin: 2px 0;
}

.table-header-context-menu__button {
  margin: 0;
  text-align: left;
  font-weight: 400;
}

.table-header-context-menu__button:hover {
  background-color: var(--black);
  border-color: var(--black);
}
.table-header-context-menu__button:hover .table-header-context-menu__button-text {
  border-bottom: 1px solid var(--white);
}

.table-header-context-menu__button--active,
.table-header-context-menu__button--active:hover {
  background-color: var(--dark);
  border-color: var(--dark);
}

.table-header-context-menu__search-type,
.table-header-context-menu__search-type:focus {
  border-color: var(--black);
  color: white;
}

.table-header-context-menu__search-type::v-deep option {
  color: var(--black);
}

.table-header-context-menu__search-field {
  display: inline-block;
  width: calc(100% - 1em);
  border: none;
  color: var(--white);
}

.table-header-context-menu__search-label {
  width: 100%;
  margin: 0;
  padding-left: 0.75rem;
}

.table-header-context-menu__search-label--empty,
.table-header-context-menu__search-field--empty {
  cursor: pointer;
}

.table-header-context-menu__search-field:hover {
  background: inherit;
}

.table-header-context-menu__search-field:focus,
.table-header-context-menu__search-field::placeholder {
  color: var(--white);
}

.table-header-context-menu__search-field:focus::placeholder {
  opacity: 0.5;
}

.table-header-context-menu__search-field::-webkit-search-cancel-button {
  -webkit-appearance: none;
  width: 1em;
  height: 1em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512' %3E%3Cpath fill='%23fff' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.table-header-context-menu__value-checkbox-group {
  margin: 1rem;
  padding: 0.5rem;
  min-height: 100px;
  max-height: 150px;
  overflow-y: scroll;
  background: var(--medium);
  color: var(--black);
}

.table-header-context-menu__value-checkbox {
  line-height: 1.5rem;
}

.table-header-context-menu__value-checkbox::v-deep label {
  max-width: 100%;
}

.table-header-context-menu__value-checkbox-inner {
  display: inline-block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-filter-datepicker {
  color: white;
  padding: 0 1rem;
}

.table-filter-datepicker .datepicker-input {
  background-color: var(--black) !important;
  color: white !important;
}
</style>
