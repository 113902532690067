import Handsontable from 'handsontable';
import Vue from 'vue';

import { Getters as ImportActivitiesGetters } from '@/activities/importActivities/store/getters';
import EquipmentService from '@/equipments/services/EquipmentService';
import { DropdownItem } from '@/shared/components/form/formFieldDropdownTypes';
import { ColumnSettingsOptional } from '@/shared/handsontable/rework/cellTypes/optionalRenderer/types';
import { columnSorting as columnSortingSubtablePrimaryColumn } from '@/shared/handsontable/rework/cellTypes/subtablePrimaryColumn';
import { ColumnSettingsSubtablePrimaryColumn } from '@/shared/handsontable/rework/cellTypes/subtablePrimaryColumn/types';
import { ColumnSettingsSubtable } from '@/shared/handsontable/rework/features/nestedTable/types';
import { DropdownItems } from '@/shared/handsontable/types';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import store from '@/store';

import { DropdownMatch } from '../../types/TableDataImportActivity.types';

function getAdditionalEquipmentDropdownItemsForImportActivity(
  equipmentIds: string[],
  equipmentService: EquipmentService,
  originalValue: string,
): DropdownItems[] {
  const additionalDropdownItems = equipmentIds.reduce((acc: DropdownItem[], equipmentId) => {
    const equipment = equipmentService.findEquipmentById(equipmentId);
    if (equipment) {
      acc.push({
        id: equipmentId,
        name: equipment.name,
      });
    }

    return acc;
  }, []);

  const originalDropdownItems = {
    id: DropdownMatch.NO_MATCH,
    name: 'value from data source (from import)',
    items: originalValue ? [{ id: DropdownMatch.NO_MATCH, name: originalValue }] : [],
    sort: false,
  };

  const additionalDropdowns = {
    name: 'Matching equipments',
    id: 'equipmenrts-match',
    items: additionalDropdownItems,
    sort: false,
  } as DropdownItems;

  if (originalValue) {
    return [originalDropdownItems, additionalDropdowns];
  }

  return [additionalDropdowns];
}

const colspanPrimaryColumn = (
  visualRow: number,
  value: DropdownItem | 'HOT_DISPLAY_BUTTON_RENDERER' | null,
  instance: Handsontable,
): number => {
  const isExpanded = instance.getDataAtRowProp(visualRow, 'expand');
  if (!isExpanded) return 2;

  if (value === null || value === 'HOT_DISPLAY_BUTTON_RENDERER' || !value.id) return 2;

  return 1;
};

const equipment: ColumnSettingsSubtable<
  Handsontable.ColumnSettings | ColumnSettingsSubtablePrimaryColumn | ColumnSettingsOptional
> = {
  data: 'equipment',
  header: {
    title: () => Vue.i18n.translate('Maschinen & Geräte') ?? '',
  },
  requiredFeatures: () =>
    store.getters.currentCompaniesHaveFeatureEnabled(availableFeatures.FEATURE_EQUIPMENT) &&
    store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_EQUIPMENT),
  type: 'subtable',
  subtableColumns: [
    {
      data: 'equipment.dropdownItem',
      type: 'farmdok.subtablePrimaryColumn',
      width: 200,
      colspan: (visualRow, visualColumn, value, instance) => colspanPrimaryColumn(visualRow, value, instance),
      collapsedSubtable: {
        getRendererValue({ values }) {
          const displayNames = values.map((value) => value.name ?? '').filter((name) => name !== '');

          return displayNames.join(', ');
        },
        onClick({ event, visualRow, instance: hot }) {
          event.preventDefault();
          hot.setDataAtRowProp(visualRow, 'expand', true);
        },
      },
      button: {
        label: () => `+ ${Vue.i18n.translate('Maschine hinzufügen')}` || '+ Maschine hinzufügen',
        color: 'primary',
        onClick({ visualRow, instance: hot }) {
          const activityId = hot.getDataAtRowProp(visualRow, 'id');
          store.dispatch('activities/importActivities/addImportActivityEquipment', activityId);
        },
      },
      showWarning: (value) => value?.id === DropdownMatch.NO_MATCH,
      dropdown: {
        async getItems(visualRow: number, hot: Handsontable) {
          const equipmentService = new EquipmentService(store.state.equipments.data);
          // find additional matches
          const importActivityId = hot.getDataAtRowProp(visualRow, 'id');
          const importActivity = (
            store.getters['activities/importActivities/getById'] as ImportActivitiesGetters['getById']
          )(importActivityId);
          const matches = importActivity.equipment[0]?.equipmentIdMatch?.additionalMatches ?? [];
          const originalValue = importActivity.equipment[0]?.equipmentIdMatch?.originalValue ?? '';

          const additionalEquipments = importActivity
            ? getAdditionalEquipmentDropdownItemsForImportActivity(matches, equipmentService, originalValue)
            : [];

          const activityTypeId: string = hot.getDataAtRowProp(visualRow, 'activityTypeDropdownItem')?.id;
          if (!activityTypeId) return additionalEquipments || [];
          const activityType = store.state.activityTypes.data[activityTypeId];

          const processOrderId: string | undefined = hot.getDataAtRowProp(visualRow, 'processOrderId');
          if (!processOrderId) return additionalEquipments || [];
          const company = store.getters['auth/findCompanyByProcessOrderId'](processOrderId);
          if (!company) return additionalEquipments || [];

          const usedEquipment = store.state.equipments.used;
          const compatibleEquipments = equipmentService.getCompatibleEquipmentsToDropdownItems(
            [activityType],
            [company],
            usedEquipment,
          );

          if (additionalEquipments) {
            return [...additionalEquipments, ...compatibleEquipments];
          }

          return compatibleEquipments;
        },
      },
      columnSorting: columnSortingSubtablePrimaryColumn,
      warningIcon: {
        tooltipLabel: Vue.i18n.translate(
          'Der Wert in der Datenquelle (aus dem Import) konnte nicht eindeutig erkannt werden. ' +
            'Bitte wähle eine Eintrag aus der Liste. Andernfalls kann kein Wert importiert werden.',
        ),
      },
    },
    // is always hidden but needed in table for change detection of product.id,
    // not allowed to be first column (otherwise column would be always hidden in tableSettings),
    // not allowed to be the last column (to properly add subtable styles)
    { data: 'equipment.id', type: 'text', hidden: true },
    {
      data: 'equipmentTrashIcon', // this is just a dummy value, the column is not bound to any data (required for move columns via user column settings)
      type: 'farmdok.trashIcon',
      renderer: 'farmdok.optional',
      noHeaderContextMenu: true,
      width: 45,
      disableColumnResize: true,
      renderEmpty(visualRow, instance) {
        const activityEquipmentId = instance.getDataAtRowProp(visualRow, 'equipment.id');
        return !activityEquipmentId;
      },
      renderNone: (visualRow, instance) => {
        const dropdownItem = instance.getDataAtRowProp(visualRow, 'equipment.dropdownItem');
        return colspanPrimaryColumn(visualRow, dropdownItem, instance) === 2;
      },
      onClick(visualRow, hot) {
        if (!this.readOnly) {
          const importActivityId = hot.getDataAtRowProp(visualRow, 'id');
          const importActivityEquipmentId = hot.getDataAtRowProp(visualRow, 'equipment.id');
          store.dispatch('activities/importActivities/removeImportActivityEquipment', {
            importActivityId,
            importActivityEquipmentId,
          });
        }
      },
    },
  ],
};

export default equipment;
