import Vue from 'vue';

export const fieldsTotalSize = {
  key: 'flaeche',
  header: {
    title: () => Vue.i18n.translate('Erntefläche gesamt (ha)'),
    description: () => Vue.i18n.translate('ha'),
  },
  type: 'numeric',
  className: 'border-right',
  width: 270,
  readOnly: true,
};

export const totalStarchAmount = {
  key: 'staerkegehalt',
  header: {
    title: () => Vue.i18n.translate('Stärkegehalt gesamt (t)'),
    description: () => Vue.i18n.translate('t'),
  },
  type: 'numeric',
  className: 'border-right',
  width: 200,
  readOnly: true,
};

export const userComment = {
  key: 'userComment',
  header: {
    title: () => Vue.i18n.translate('Bemerkung'),
  },
  type: 'text',
  className: 'border-right',
};

export const creationDate = {
  key: 'creationDate',
  header: {
    title: () => Vue.i18n.translate('Datum'),
  },
  type: 'date',
  className: 'border-right',
  width: 150,
};

export const amount = {
  key: 'amount',
  header: {
    title: () => Vue.i18n.translate('Menge (t)'),
    description: () => Vue.i18n.translate('t'),
  },
  type: 'numeric',
  className: 'border-right',
  width: 130,
};

export const amountSugar = {
  key: 'reineruebe',
  header: {
    title: () => Vue.i18n.translate('Reine Rübe (t)'),
    description: () => Vue.i18n.translate('t'),
  },
  type: 'numeric',
  readOnly: true,
  className: 'border-right',
  width: 130,
};

export const amountPotato = {
  key: 'reinekartoffel',
  header: {
    title: () => Vue.i18n.translate('Menge (t)'),
    description: () => Vue.i18n.translate('t'),
  },
  type: 'numeric',
  readOnly: true,
  className: 'border-right',
  width: 130,
};

export const ingredientSugar = {
  key: 'zuckergehalt',
  header: {
    title: () => Vue.i18n.translate('Zuckergehalt (t)'),
    description: () => Vue.i18n.translate('t'),
  },
  type: 'numeric',
  readOnly: true,
  className: 'border-right',
  width: 150,
};

export const ingredientPotato = {
  key: 'staerkegehalt',
  header: {
    title: () => Vue.i18n.translate('Stärkegehalt (t)'),
    description: () => Vue.i18n.translate('t'),
  },
  type: 'numeric',
  readOnly: true,
  className: 'border-right',
  width: 150,
};

export const agranaFieldNameCombined = {
  key: 'feldnameCombined',
  header: {
    title: () => Vue.i18n.translate('AGRANA FSNr - Feldname'),
  },
  className: 'border-right',
  type: 'text',
  readOnly: true,
  width: 300,
};

export const fieldId = {
  key: 'fieldId',
  header: {
    title: () => Vue.i18n.translate('FSNr - Schlag'),
  },
  className: 'border-right text-bold',
  type: 'dropdown',
  required: true,
  width: 300,
};

export const processedArea = {
  key: 'processedArea',
  header: {
    title: () => Vue.i18n.translate('Hektar'),
    description: () => Vue.i18n.translate('ha'),
  },
  className: 'border-right',
  type: 'numeric',
  required: true,
  width: 130,
};
