
import numbro from 'numbro';
import { defineComponent } from 'vue';
import { mapGetters, mapState } from 'vuex';

import ResultTable from '@/precision-farming/application-maps/components/resultTable/ResultTable.vue';
import { ColumnSettings, TableData } from '@/precision-farming/application-maps/components/resultTable/types';
import { NutrientDosage } from '@/precision-farming/application-maps/fertilization/store/types';
import { Getters } from '@/precision-farming/application-maps/fertilizationCereals/store/getters';

import { ActionPayloads } from '../../store/actions';

export default defineComponent({
  name: 'ZoneDosageTableContainer',
  components: {
    ResultTable,
  },
  data() {
    const columns: ColumnSettings[] = [
      {
        key: 'zone',
        header: {
          title: () => this.$t('Zone') || 'Zone',
        },
        type: 'badge',
      },
      {
        key: 'dosage',
        header: {
          title: () => this.$t('Dosierung') || 'Dosierung',
        },
        type: 'numberInput',
      },
      {
        key: 'n',
        header: {
          title: () => 'N',
        },
        type: 'numberInput',
      },
      {
        key: 'p',
        header: {
          title: () => 'P₂O₅',
        },
        type: 'numberInput',
      },
      {
        key: 'k',
        header: {
          title: () => 'K₂O',
        },
        type: 'numberInput',
      },
    ];

    return {
      columns,
    };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/fertilizationCereals', ['zones', 'calculation']),
    ...mapGetters({
      zoneDosage: 'precisionFarming/applicationMaps/fertilizationCereals/zoneDosage',
      weightedAverageDosage: 'precisionFarming/applicationMaps/fertilizationCereals/weightedAverageDosage',
    }),
    zoneDosageTableData(): TableData[] {
      return (
        (this.zoneDosage as Getters['zoneDosage'])?.map((zone) => ({
          zone: {
            name: zone.name,
            color: zone.color,
          },
          dosage: {
            value: zone.dosage,
          },
          n: { value: zone.n, disabled: this.calculation.n === 0 },
          p: { value: zone.p, disabled: this.calculation.p === 0 },
          k: { value: zone.k, disabled: this.calculation.k === 0 },
        })) ?? []
      );
    },
    footer(): TableData {
      return {
        zone: this.$t('Mittel') || 'Mittel',
        dosage: numbro(this.weightedAverageDosage).format({ mantissa: 0 }),
        n: this.getWeightedAvgDosage(this.calculation.n),
        p: this.getWeightedAvgDosage(this.calculation.p),
        k: this.getWeightedAvgDosage(this.calculation.k),
      };
    },
    loading(): boolean {
      return this.zones.loading;
    },
  },
  methods: {
    onInput({ key, rowIndex, value }: { key: keyof NutrientDosage; rowIndex: number; value: number }) {
      const payload: ActionPayloads['setManualDosage'] = {
        key,
        index: rowIndex,
        value,
      };
      this.$store.dispatch('precisionFarming/applicationMaps/fertilizationCereals/setManualDosage', payload);
    },
    getWeightedAvgDosage(calculationValue: number) {
      const avg = this.weightedAverageDosage * calculationValue;
      return numbro(avg).format({ mantissa: 0 });
    },
  },
});
