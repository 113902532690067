
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import ResultTable from '@/precision-farming/application-maps/components/resultTable/ResultTable.vue';
import { ColumnSettings, TableData } from '@/precision-farming/application-maps/components/resultTable/types';

import { Zones } from '../../store/types';

export default defineComponent({
  name: 'ResultTableContainer',
  components: {
    ResultTable,
  },
  data() {
    const columns: ColumnSettings[] = [
      {
        key: 'zone',
        header: {
          title: () => this.$t('Zone') || 'Zone',
        },
        type: 'badge',
      },
      {
        key: 'size',
        header: {
          title: () => this.$t('Fläche in ha') || 'Fläche in ha',
        },
        type: 'number',
      },
      {
        key: 'minNdvi',
        header: {
          title: () => this.$t('Min. NDVI') || 'Min. NDVI',
        },
        type: 'number',
      },
      {
        key: 'avgNdvi',
        header: {
          title: () => this.$t('Avg. NDVI') || 'Avg. NDVI',
        },
        type: 'number',
      },
      {
        key: 'maxNdvi',
        header: {
          title: () => this.$t('Max. NDVI') || 'Max. NDVI',
        },
        type: 'number',
      },
    ];

    return {
      columns,
    };
  },
  computed: {
    ...mapState('precisionFarming/applicationMaps/fertilizationCereals', ['zones']),
    tableData(): TableData[] {
      return (
        (this.zones as Zones).data?.map((zone) => ({
          zone: {
            name: zone.name,
            color: zone.color,
          },
          size: zone.size,
          minNdvi: zone.minNdvi ?? 0,
          avgNdvi: zone.avgNdvi ?? 0,
          maxNdvi: zone.maxNdvi ?? 0,
        })) || []
      );
    },
    loading(): boolean {
      return (this.zones as Zones).loading;
    },
  },
});
