import Vue from 'vue';

import '@/initI18n';

import { baseUrl } from '@/shared/constants';
import { availableFeatures } from '@/shared/storeDynamicFeatures';
import store from '@/store';

import PageContracting from './PageContracting.vue';
import PageImportContract from './PageImportContract.vue';
import PageMyContracts from './PageMyContracts.vue';
import PageOpenCalls from './PageOpenCalls.vue';
import PageViewContract from './PageViewContract.vue';
import PageContractFileMap from './managed-contracts/PageContractFileMap.vue';
import managedContractsRoutes from './managed-contracts/routes';

const tabs = () => {
  const currentTabs = ['digital-contracting/my-contracts', 'digital-contracting/open-calls'];
  if (store.getters.currentCompaniesHaveFeatureVisible(availableFeatures.FEATURE_MANAGED_CONTRACTS)) {
    currentTabs.push('digital-contracting/managed-contracts');
  }
  return currentTabs;
};

export const viewContractBeforeEnter = async (to, from, next) => {
  const contractCompanyId = Number(to.params.contractCompanyId);
  await store.dispatch('digitalContracting/subscribe');
  if (
    !Object.values(store.state.digitalContracting.data).some(
      (contract) => contract.contractCompanyId === contractCompanyId,
    )
  ) {
    next({ name: 'digital-contracting/my-contracts' });
    return;
  }
  next();
};
export const viewContractTabs = (route) => {
  const contractCompanyId = Number(route.params.contractCompanyId);
  let contract = null;
  Object.values(store.state.digitalContracting.data).some((currentContract) => {
    if (currentContract.contractCompanyId !== contractCompanyId) {
      return false;
    }
    contract = currentContract;
    return true;
  });
  if (contract != null && contract.periodEnd * 1000 < new Date()) {
    return ['digital-contracting/view-contract', 'digital-contracting/import-contract'];
  }
  return null;
};
export const viewContractLabel = (route) => {
  let label = Vue.i18n.translate('Flächenübermittlung');
  const contractCompanyId = Number(route.params.contractCompanyId);
  Object.values(store.state.digitalContracting.data).some((contract) => {
    if (contract.contractCompanyId !== contractCompanyId) {
      return false;
    }
    label = contract.name;
    return true;
  });
  return label;
};

export default [
  ...managedContractsRoutes,
  {
    path: `${baseUrl}digital-contracting`,
    name: 'digital-contracting',
    redirect: { name: 'digital-contracting/my-contracts' },
    meta: {
      requiresAuth: true,
      tabs,
      label: () => Vue.i18n.translate('Vertragsproduktion'),
      noMultiCompany: true,
      featureControl: availableFeatures.FEATURE_DIGITAL_CONTRACTING,
    },
  },
  {
    path: `${baseUrl}digital-contracting/my-contracts`,
    name: 'digital-contracting/my-contracts',
    component: PageMyContracts,
    meta: {
      requiresAuth: true,
      tabs,
      label: () => Vue.i18n.translate('Vertragsproduktion'),
      labelTab: () => Vue.i18n.translate('Meine Verträge'),
      noMultiCompany: true,
      featureControl: availableFeatures.FEATURE_DIGITAL_CONTRACTING,
    },
  },
  {
    path: `${baseUrl}digital-contracting/view-contract/:contractCompanyId`,
    name: 'digital-contracting/view-contract',
    component: PageViewContract,
    beforeEnter: viewContractBeforeEnter,
    meta: {
      requiresAuth: true,
      tabs: viewContractTabs,
      label: viewContractLabel,
      labelTab: () => Vue.i18n.translate('Gesendet'),
      noMultiCompany: true,
      featureControl: availableFeatures.FEATURE_DIGITAL_CONTRACTING,
    },
  },
  {
    path: `${baseUrl}digital-contracting/import-contract/:contractCompanyId`,
    name: 'digital-contracting/import-contract',
    component: PageImportContract,
    beforeEnter: viewContractBeforeEnter,
    meta: {
      requiresAuth: true,
      tabs: viewContractTabs,
      label: viewContractLabel,
      labelTab: () => Vue.i18n.translate('Empfangen'),
      noMultiCompany: true,
      featureControl: availableFeatures.FEATURE_DIGITAL_CONTRACTING,
    },
  },
  {
    path: `${baseUrl}digital-contracting/open-calls`,
    name: 'digital-contracting/open-calls',
    component: PageOpenCalls,
    meta: {
      requiresAuth: true,
      tabs,
      label: () => Vue.i18n.translate('Vertragsproduktion'),
      labelTab: () => Vue.i18n.translate('Offene Verträge'),
      noMultiCompany: true,
      featureControl: availableFeatures.FEATURE_DIGITAL_CONTRACTING,
    },
  },
  {
    path: `${baseUrl}digital-contracting/contracting/:contractGuid`,
    name: 'digital-contracting/contracting',
    component: PageContracting,
    meta: {
      requiresAuth: true,
      label() {
        let label = Vue.i18n.translate('Flächen senden');
        if (store.state.digitalContracting.contracting != null) {
          label = store.state.digitalContracting.contracting.name;
        }
        return label;
      },
      noMultiCompany: true,
      featureControl: availableFeatures.FEATURE_DIGITAL_CONTRACTING,
    },
  },
  {
    path: `${baseUrl}digital-contracting/contracting-resend/:contractCompanyId`,
    name: 'digital-contracting/contracting-resend',
    component: PageContracting,
    props: {
      modeResend: true,
    },
    meta: {
      requiresAuth: true,
      label() {
        let label = Vue.i18n.translate('Erneut senden');
        if (store.state.digitalContracting.contracting != null) {
          label = `${label} - ${store.state.digitalContracting.contracting.name}`;
        }
        return label;
      },
      noMultiCompany: true,
      featureControl: availableFeatures.FEATURE_DIGITAL_CONTRACTING,
    },
  },
  {
    path: `${baseUrl}digital-contracting/contractFiles/:fileId`,
    name: 'digital-contracting/contractFiles',
    component: PageContractFileMap,
    meta: {
      isFullscreen: true,
    },
  },
];
