
import numbro from 'numbro';
import { defineComponent } from 'vue';

import { combinedNameExclCrop } from '@/fields/handsontable/columns/columns';
import { Field } from '@/shared/api/rest/models';

import Export from '../components/export/Export.vue';
import ExportTable from '../components/export/ExportTable.vue';
import { ZoneGenerationMode } from '../store/baseWorkflowStore/types';
import { ColorCode, Heatmap } from '../store/baseWorkflowStore/types/Heatmap';
import { roundToPrecision } from './store/common';
import { Calculation, SeedingZone } from './store/types';

type TableRow = {
  title: () => string | null;
  name: string;
  values: string[];
};

type FieldValues = {
  name: string;
  title: string | null;
  avgSeedRate: number;
};

export default defineComponent({
  name: 'SidebarExportSeeding',
  components: {
    Export,
    ExportTable,
  },
  props: {
    workflowKey: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tableHeaders: [this.$t('Saatst\u00e4rke')],
      errorMessage: null as string | null,
    };
  },
  computed: {
    fields(): Record<string, Field> {
      return this.$store.state.fields.data;
    },
    calculation(): Calculation {
      return this.$store.getters[`precisionFarming/applicationMaps/seeding/calculation`];
    },
    currentHeatmaps(): Record<string, Heatmap> {
      return this.$store.getters[`precisionFarming/applicationMaps/seeding/currentHeatmaps`];
    },
    zones(): SeedingZone[] {
      return this.$store.getters[`precisionFarming/applicationMaps/seeding/zones`];
    },
    zoneGenerationMode(): ZoneGenerationMode {
      return this.$store.state.precisionFarming.applicationMaps.seeding.zoneGenerationMode;
    },
    selectedFields(): string[] {
      return this.$store.state.precisionFarming.applicationMaps.seeding.selectedFields;
    },
    paginationStep(): number {
      return this.$store.state.precisionFarming.applicationMaps.seeding.paginationStep;
    },
    fieldValues(): FieldValues[] {
      const fieldIds = this.fieldIdsOfHeatmaps(this.currentHeatmaps);
      return fieldIds.map((fieldId) => this.calcFieldValues(fieldId));
    },
    tableRows(): TableRow[] {
      return this.fieldValues.map((field) => ({
        title: () => field.title,
        name: field.name,
        values: [numbro(field.avgSeedRate).format({ mantissa: 0 })],
      }));
    },
    paginationStepOrSelectedFieldsOrVarietyChanged(): string {
      return `${this.paginationStep}|${this.selectedFields}|${this.calculation.variety}`;
    },
  },
  watch: {
    paginationStepOrSelectedFieldsOrVarietyChanged() {
      if (this.paginationStep === 3) {
        this.$store.dispatch(`precisionFarming/applicationMaps/${this.workflowKey}/findAndSetMaterial`);
      }
    },
  },
  methods: {
    fieldIdsOfHeatmaps(heatmaps: Record<string, Heatmap>): string[] {
      return Object.keys(heatmaps).map((heatmapId) => {
        const guid = heatmapId.split('_')[0];
        return guid;
      });
    },
    calcFieldValues(fieldId: string): FieldValues {
      const name = this.getFieldName(fieldId);
      const title = this.getFieldTitle(name);
      const avgSeedRate = this.calcAvgSeedRate(fieldId);

      return {
        name,
        title,
        avgSeedRate,
      };
    },
    getFieldName(fieldId: string): string {
      return combinedNameExclCrop.data.call(this, this.fields[fieldId]);
    },
    getFieldTitle(fieldName: string): string | null {
      if (fieldName.length > 19) {
        return fieldName;
      }
      return null;
    },
    calcAvgSeedRate(fieldId: string): number {
      let avgSeedRate = 0;

      const heatmap = this.findHeatmapOfField(fieldId);
      if (!heatmap) {
        return avgSeedRate;
      }
      heatmap.color_codes.forEach((colorCode) => {
        const zone = this.findZoneOfColorCode(colorCode);

        const seedRate = zone?.manualSeedRate || zone?.seedRateWithLossCompensation || 0;
        avgSeedRate += seedRate * colorCode.area;
      });

      const heatmapTotalSize = heatmap.color_codes.reduce((total, colorCode) => total + colorCode.area, 0);

      if (heatmapTotalSize > 0) {
        avgSeedRate /= heatmapTotalSize;
      }

      avgSeedRate = roundToPrecision(avgSeedRate);
      return avgSeedRate;
    },
    findHeatmapOfField(fieldId: string): Heatmap | undefined {
      const entry = Object.entries(this.currentHeatmaps).find(([heatmapId]) => heatmapId.split('_')[0] === fieldId);
      const heatmap = entry?.[1];
      return heatmap;
    },
    findZoneOfColorCode(colorCode: ColorCode): SeedingZone | undefined {
      return this.zones.find((zone) => zone.color === colorCode.col);
    },
    setErrorMessage(message: string) {
      this.errorMessage = message;
    },
  },
});
