
import { defineComponent } from 'vue';

import numbro from '@/initNumbro';
import ResultTable from '@/precision-farming/application-maps/components/resultTable/ResultTable.vue';
import { ColumnSettings, TableData } from '@/precision-farming/application-maps/components/resultTable/types';
import weightedAverage from '@/shared/modules/weightedAverage';

import { roundToPrecision } from '../../store/common';
import { Getters } from '../../store/getters';
import { SeedingZone } from '../../store/types';

export default defineComponent({
  name: 'ZonesTableContainer',
  components: { ResultTable },
  data() {
    const columns: ColumnSettings[] = [
      {
        key: 'zone',
        header: {
          title: () => this.$t('Zone') || 'Zone',
        },
        type: 'badge',
      },
      {
        key: 'seedRate',
        header: {
          title: () => this.$t('Saatstärke in Körner/ha') || 'Saatstärke in Körner/ha',
        },
        type: 'numberInput',
        align: 'right',
      },
    ];

    return { columns };
  },
  computed: {
    zones(): SeedingZone[] {
      return this.$store.getters['precisionFarming/applicationMaps/seeding/zones'];
    },
    weightedAvgSeedRate(): number {
      const seedRates = this.zones.map(
        (zone: SeedingZone) => zone.manualSeedRate ?? zone.seedRateWithLossCompensation ?? 0,
      );
      const zoneSizes = this.zones.map((zone: SeedingZone) => zone.size);
      return roundToPrecision(weightedAverage(seedRates, zoneSizes));
    },
    tableData(): TableData[] {
      return this.zones.map((zone: SeedingZone) => {
        // display 0 if zone size is 0
        const seedRateValue = zone.size === 0 ? 0 : zone.manualSeedRate ?? zone.seedRateWithLossCompensation ?? 0;
        return {
          zone: {
            name: '',
            color: zone.color,
          },
          seedRate: { value: seedRateValue },
        };
      });
    },
    footer(): TableData {
      return {
        zone: this.$t('Durchschnitt') || 'Durchschnitt',
        seedRate: numbro(this.weightedAvgSeedRate).format({ mantissa: 0 }),
      };
    },
  },
  watch: {
    weightedAvgSeedRate() {
      this.$store.dispatch(`precisionFarming/applicationMaps/seeding/setAverageDosage`, this.weightedAvgSeedRate);
    },
  },
  methods: {
    onInput({ key, rowIndex, value }: { key: string; rowIndex: number; value: number | undefined }) {
      if (key !== 'seedRate') return;

      const zoneName = (this.zones as Getters['zones'])[rowIndex]?.name;
      this.setManualSeedRate(zoneName, value ?? 0);
    },
    setManualSeedRate(zoneName: string, manualSeedRate: number) {
      this.$store.dispatch('precisionFarming/applicationMaps/seeding/setManualSeedRate', { zoneName, manualSeedRate });
    },
  },
});
